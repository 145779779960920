import type { MantineColor } from '@mantine/core';
import { Text, ThemeIcon, Tooltip } from '@mantine/core';

import { IconQuestionMark } from '@tabler/icons-react';

type ToolTipProps = {
  content: string;
  icon?: React.ReactNode;
  color?: MantineColor;
};

export default function TooltipWithIcon({
  content,
  icon = <IconQuestionMark size={16} stroke={3} />,
  color = 'primary.6',
}: ToolTipProps) {
  return (
    <Tooltip
      label={
        <Text variant="xs" fw="600">
          {content}
        </Text>
      }
      position="right"
      width={340}
      withArrow
      arrowSize={6}
      color={color}
      multiline
    >
      <ThemeIcon
        variant="outline"
        radius="xl"
        sx={{
          border: '2px solid',
        }}
        color={color}
        size={16}
      >
        {icon}
      </ThemeIcon>
    </Tooltip>
  );
}
