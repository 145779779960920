import { Box, Flex, Select, useMantineTheme } from '@mantine/core';

import { IconChevronDown, IconEye } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '../UI/Button/Button';
import { useSidepanelContext } from '../UI/Sidepanel/SidepanelInternalData.provider';

import type { DataPointSource } from '../../entities/DataPointMeta';
import type { Document } from '../../entities/Document';
import {
  DocumentPreview,
  SUPPORTED_OFFICE_EXTENSIONS,
} from '../DocumentPreview/DocumentPreview';
import { DownloadFileButton } from '../DownloadFileButton';
import { SourceExtractPreview } from './SourceExtractPreview';
import { formatSourceLabel } from './SourcesSection';

export const SOURCE_SIDE_PANEL_WIDTH = 800;

export type SourceSidepanelContext = {
  sources: DataPointSource[];
  documents: Document[];
  selectedSource: DataPointSource;
};

export function Header() {
  const {
    data: { sources, documents, selectedSource },
    updateData,
  } = useSidepanelContext<SourceSidepanelContext>();
  const initialSelectedSourceIndex = sources.findIndex(
    ({ docFilePath, pageNumber }) =>
      docFilePath === selectedSource.docFilePath &&
      pageNumber === selectedSource.pageNumber,
  );

  const selectData = sources.map((source, index) => ({
    value: index.toString(),
    label: formatSourceLabel(source),
  }));

  function handleSourceChange(value: string) {
    const source = sources[parseInt(value)];

    updateData({
      sources,
      documents,
      selectedSource: source,
    });
  }

  const selectedDocument = getSelectedDocument(documents, selectedSource);
  return (
    <Flex justify="space-between" w="100%">
      <Flex justify="center" align="center" w="100%">
        <Select
          styles={theme => ({
            root: { minWidth: '450px' },
            input: {
              border: 'none',
              textAlign: 'center',
              fontWeight: 500,
              color: theme.colors.gray[9],
              fontSize: theme.fontSizes.sm,
            },
            dropdown: { minWidth: 'fit-content' },
            item: { wordSpacing: 'nowrap' },
          })}
          rightSection={<IconChevronDown size={16} />}
          value={initialSelectedSourceIndex.toString()}
          data={selectData}
          onChange={handleSourceChange}
        />
      </Flex>

      <DownloadFileButton
        fileURL={selectedDocument.fileURL}
        prettyName={selectedDocument.prettyName}
      />
    </Flex>
  );
}

export function Content({
  tenderId,
  onClose,
}: {
  tenderId: number;
  onClose: () => void;
}) {
  const theme = useMantineTheme();
  const { t } = useTranslation('notice');
  const {
    data: { documents, selectedSource },
  } = useSidepanelContext<SourceSidepanelContext>();

  const selectedDocument = getSelectedDocument(documents, selectedSource);

  const extension =
    selectedDocument.fileURL.split('.').pop()?.toLowerCase() || '';
  const isDocX = SUPPORTED_OFFICE_EXTENSIONS.includes(extension);

  return (
    <Box px="04" w={SOURCE_SIDE_PANEL_WIDTH} h="100%">
      {isDocX ? (
        <>
          <SourceExtractPreview extract={selectedSource.originalChunkExtract} />
          <Button
            variant="default"
            color="primary"
            mt="04"
            size="sm"
            component={Link}
            to={`/notices/${tenderId}?tab=documents`}
            onClick={onClose}
            leftIcon={<IconEye color={theme.colors.primary[7]} />}
          >
            {t('documents.seeFullDocument')}
          </Button>
        </>
      ) : (
        <DocumentPreview
          search={selectedSource.originalChunkExtract}
          initialPage={selectedSource.pageNumber ?? 1}
          fileURL={selectedDocument.fileURL}
          prettyName={selectedDocument.prettyName}
        />
      )}
    </Box>
  );
}

function getSelectedDocument(
  documents: Document[],
  selectedSource: DataPointSource,
) {
  const selectedDocument = documents.find(
    doc => doc.originalName === selectedSource.docFilePath,
  );

  if (!selectedDocument) {
    throw new Error('Selected document not found');
  }
  return selectedDocument;
}
