import { Grid, Stack, Text } from '@mantine/core';

import { IconInfoSquareRounded } from '@tabler/icons-react';

import { BrandedCard } from '../../../../shared/components/UI/BrandedCard/BrandedCard';
import type { DividerTitleColor } from '../../../../shared/components/UI/BrandedCard/BrandedCardTitle';

import { DecisionStatus } from '../../../../shared/entities/Interaction';
import type Tender from '../../../../shared/entities/Tender';
import { formatCurrency } from '../../../../shared/utils/curencies';
import { useNoticeContext } from '../../contexts/Notice.provider';
import BuyerCard from '../InfoCards/BuyerCard';
import ComplementaryDataCard from '../InfoCards/ComplementaryDataCard';
import DataCard from '../InfoCards/DataCard';
import DescriptionCard from '../InfoCards/DescriptionCard';
import LotsCard from '../InfoCards/LotCard';
import TimelineCard from '../InfoCards/TimelineCard';

type BaseConfig = {
  color: DividerTitleColor;
  title: string;
  icon: React.ReactNode;
  reason: string | undefined;
};

type WinConfig = BaseConfig & {
  type: DecisionStatus.WIN;
  amount?: number;
};

type LossConfig = BaseConfig & {
  type: DecisionStatus.LOSS;
};

type NoGoConfig = BaseConfig & {
  type: DecisionStatus.NOGO;
};

type ReasonConfig = WinConfig | LossConfig | NoGoConfig;

export default function InfoTab() {
  return (
    <Stack spacing="05" maw="100%" mb="06">
      <Grid>
        <Grid.Col lg={8} xl={9}>
          <Stack spacing="05" h="100%">
            <DataCard />
            <DescriptionCard />
          </Stack>
        </Grid.Col>
        <Grid.Col lg={4} xl={3}>
          <Stack spacing="05">
            <ReasonCard />
            <TimelineCard />
            <ComplementaryDataCard />
          </Stack>
        </Grid.Col>
      </Grid>
      <LotsCard />
      <BuyerCard />
    </Stack>
  );
}

const ReasonCard = () => {
  const { tender } = useNoticeContext();
  if (
    !tender.interaction ||
    (tender.interaction.decisionStatus !== DecisionStatus.NOGO &&
      tender.interaction.decisionStatus !== DecisionStatus.LOSS &&
      tender.interaction.decisionStatus !== DecisionStatus.WIN)
  ) {
    return null;
  }

  const reasonConfig =
    getReasonConfig(tender)[tender.interaction?.decisionStatus];
  return (
    <BrandedCard
      title={reasonConfig.title}
      icon={reasonConfig.icon}
      w="100%"
      variant="outlined"
      color={reasonConfig.color}
    >
      <Stack spacing={"05"} h="100%">
        {reasonConfig.reason?.split('\n').map(reason => (
          <Text key={reason} variant="sm" fw={400} color="gray.9">
            {reason}
          </Text>
        ))}
        {reasonConfig.type === DecisionStatus.WIN && reasonConfig.amount && (
          <AmountSection amount={reasonConfig.amount} />
        )}
      </Stack>
    </BrandedCard>
  );
};

const getReasonConfig = (
  tender: Tender,
): Record<
  DecisionStatus.NOGO | DecisionStatus.LOSS | DecisionStatus.WIN,
  ReasonConfig
> => ({
  [DecisionStatus.NOGO]: {
    type: DecisionStatus.NOGO,
    color: 'red',
    title: 'Raison de No go',
    icon: <IconInfoSquareRounded size={16} />,
    reason: tender.interaction?.nogoReason,
  },
  [DecisionStatus.WIN]: {
    type: DecisionStatus.WIN,
    color: 'primary',
    title: 'Raison de victoire',
    icon: <IconInfoSquareRounded />,
    reason: tender.interaction?.resultReason,
    amount: tender.interaction?.winningAmount,
  },
  [DecisionStatus.LOSS]: {
    type: DecisionStatus.LOSS,
    color: 'red',
    title: 'Raison de perte',
    icon: <IconInfoSquareRounded />,
    reason: tender.interaction?.resultReason,
  },
});

function AmountSection({ amount }: { amount: number }) {
  return (
    <Text variant="sm" component="span" fw={500} color="gray.9">
      Montant gagné: {formatCurrency(amount)}
    </Text>
  );
}
