import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSummaryCriterionAnswers } from '../../../../../shared/api/magellan/summarySheet';
import { getTender } from '../../../../../shared/api/magellan/tender';
import { DecisionStatus } from '../../../../../shared/entities/Interaction';
import { upsertDecision } from '../../../../../shared/utils/upsertDecision';

export const useDecisionMutation = (tenderId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      decision,
      reason,
      winningAmount,
    }: {
      decision: DecisionStatus;
      reason?: string;
      winningAmount?: number;
    }) =>
      upsertDecision({
        tenderId,
        status: { type: 'DecisionStatus', value: decision },
        reason,
        winningAmount,
      }),
    onSuccess: (_, { decision }) => {
      queryClient.invalidateQueries({ queryKey: [getTender.name, tenderId] });
      if (decision === DecisionStatus.TO_ANALYZE) {
        queryClient.invalidateQueries({
          queryKey: [getSummaryCriterionAnswers.name, tenderId],
        });
      }
    },
  });
};
