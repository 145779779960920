import { Group } from '@mantine/core';
import type { MRT_ColumnDef } from 'mantine-react-table';

import type { TFunction } from 'i18next';

import { RenewalTag } from '../../../../../shared/components/UI/RenewalTag/RenewalTag';
import TenderStatusBadge from '../../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';
import { TenderTitle } from '../../../../../shared/components/UI/TenderTitle/TenderTitle';
import { UserWithAvatar } from '../../../../../shared/components/UI/UserWithAvatar/UserWithAvatar';

import * as dateUtils from '../../../../../shared/utils/dates';
import type { TenderWithListData } from '../../../../../shared/api/magellan/interaction/dto';
import type { DecisionStatus } from '../../../../../shared/entities/Interaction';
import type { TenderStatus } from '../../../../../shared/entities/Tender';
import { formatDateInParisTZ } from '../../../../../shared/utils/dates';
import { DecisionActionButton } from '../components/DecisionActionButton';
import { CollaborationCell } from '../components/cells/CollaborationCell';
import { ReasonCell } from '../components/cells/ReasonCell';
import {
  SummarySheetRecapCell,
  type SummarySheetRecapCellData,
} from '../components/cells/SummarySheetRecapCell';
import { WinAmountCell } from '../components/cells/WinAmountCell';

export enum TableColumn {
  OPPORTUNITY = 'opportunity',
  STATUS = 'status',
  RESPONSE_DEADLINE = 'responseDeadline',
  OWNER = 'owner',
  NO_GO = 'nogoReason',
  LOSS = 'lossReason',
  WIN = 'winReason',
  WIN_AMOUNT = 'winAmount',
  CONTRIBUTIONS = 'contributions',
  ACTIONS = 'actions',
  ANSWERED_AT = 'answeredAt',
  SUMMARY_SHEET_RECAP = 'summarySheetRecap',
}

export type ActiveDecisionStatus = Exclude<
  DecisionStatus,
  DecisionStatus.PENDING | DecisionStatus.REJECTED
>;

const fixedWidthProps = (width?: number) => ({
  size: width,
  maxSize: width,
  minSize: width,
  mantineTableBodyCellProps: {
    sx: { flex: `0 0 ${width}px`, flexGrow: 0 },
  },
  mantineTableHeadCellProps: {
    sx: { flex: `0 0 ${width}px`, flexGrow: 0 },
  },
});

type OpportunityCellData = {
  buyerOriginalName: string;
  logoURL?: string;
  tenderTitle: string;
  buyerId: number;
};

export type I18nTFunction = TFunction<'pipeline'>;
type ColumnType = (t: I18nTFunction) => MRT_ColumnDef<TenderWithListData>;

export const opportunity: ColumnType = t => ({
  header: t('cells.opportunity.title'),
  id: TableColumn.OPPORTUNITY,
  accessorFn: tender =>
    JSON.stringify({
      buyerOriginalName: tender.buyer.originalName,
      logoURL: tender.buyer.logoURL,
      tenderTitle: tender.title,
      buyerId: Number(tender.buyer.id),
    }),
  Cell: ({ cell }) => {
    const { buyerOriginalName, logoURL, tenderTitle } = JSON.parse(
      cell.getValue<string>(),
    ) as OpportunityCellData;
    return (
      <TenderTitle
        buyerOriginalName={buyerOriginalName}
        logoURL={logoURL}
        tenderTitle={tenderTitle}
        withTooltip
        size="sm"
      />
    );
  },
});

type StatusCellData = {
  status: TenderStatus;
  isRenewal: boolean;
};

export const status: ColumnType = t => ({
  header: t('cells.status.title'),
  id: TableColumn.STATUS,
  accessorFn: tender =>
    JSON.stringify({
      status: tender.status,
      isRenewal: tender.isRenewal,
    } as StatusCellData),
  enableColumnFilter: false,
  Cell: ({ cell }) => {
    const { status, isRenewal } = JSON.parse(
      cell.getValue<string>(),
    ) as StatusCellData;
    return (
      <Group noWrap spacing="01">
        <TenderStatusBadge status={status} />
        {isRenewal && <RenewalTag size="xs" />}
      </Group>
    );
  },
  filterFn: (row, _columnId, filterValue: TenderStatus[]) => {
    const status = row.original.status;
    return filterValue.includes(status);
  },
  ...fixedWidthProps(120),
});

export const responseDeadline: ColumnType = t => ({
  header: t('cells.responseDeadline.title'),
  id: TableColumn.RESPONSE_DEADLINE,
  accessorFn: tender => formatDateInParisTZ(tender.responseDeadline),
  enableColumnFilter: false,
  filterFn: (row, _columnId, filterValue: string[]) => {
    if (!row.original.responseDeadline) {
      return false;
    }

    const responseDeadline = new Date(row.original.responseDeadline);
    responseDeadline.setHours(0, 0, 0, 0);

    const [dateString] = filterValue;

    return (
      dateUtils.parseFrLocaleDateStringToDate(dateString) >= responseDeadline
    );
  },
  ...fixedWidthProps(140),
});

type OwnerCellData =
  | undefined
  | {
      avatarColor: string;
      firstName: string;
      lastName: string;
      disabledAt: Date | null;
    };

export const owner: ColumnType = t => ({
  header: t('cells.owner.title'),
  id: TableColumn.OWNER,
  accessorFn: tender => tender.interaction?.owner,
  enableColumnFilter: false,
  Cell: ({ cell }) => {
    const owner = cell.getValue<OwnerCellData>();
    if (!owner) {
      return null;
    }
    return (
      <UserWithAvatar
        avatarColor={owner.avatarColor}
        firstName={owner.firstName}
        lastName={owner.lastName}
        isDisabled={!!owner.disabledAt}
      />
    );
  },
  filterFn: (row, _columnId, filterValue: string[]) => {
    if (!row.original.interaction?.owner) {
      return false;
    }

    return filterValue.includes(row.original.interaction.owner.id);
  },
  ...fixedWidthProps(160),
});

export const nogo: ColumnType = t => ({
  header: t('cells.nogoReason.title'),
  id: TableColumn.NO_GO,
  accessorFn: tender => ({ reason: tender.interaction?.nogoReason }),
  Cell: ReasonCell,
  ...fixedWidthProps(320),
});

export const lossReason: ColumnType = t => ({
  header: t('cells.lossReason.title'),
  id: TableColumn.LOSS,
  accessorFn: tender => ({ reason: tender.interaction?.resultReason }),
  Cell: ReasonCell,
  ...fixedWidthProps(320),
});

export const winReason: ColumnType = t => ({
  header: t('cells.winReason.title'),
  id: TableColumn.WIN,
  accessorFn: tender => ({ reason: tender.interaction?.resultReason }),
  Cell: ReasonCell,
  ...fixedWidthProps(320),
});

export const winAmount: ColumnType = t => ({
  header: t('cells.winAmount.title'),
  id: TableColumn.WIN_AMOUNT,
  accessorFn: tender => ({ amount: tender.interaction?.winningAmount }),
  Cell: WinAmountCell,
  ...fixedWidthProps(100),
});

type NoteCellData = {
  tenderId: number;
  note: string;
  numberOfComments: number;
};

export const contributions: ColumnType = t => ({
  header: t('cells.contributions.title'),
  id: TableColumn.CONTRIBUTIONS,
  accessorFn: tender => ({
    note: tender.interaction?.note,
    tenderId: tender.id,
    numberOfComments: tender.numberOfComments,
  }),
  Cell: ({ cell }) => {
    const { tenderId, note, numberOfComments } = cell.getValue<NoteCellData>();

    return (
      <CollaborationCell
        tenderId={tenderId}
        note={note}
        numberOfComments={numberOfComments}
      />
    );
  },
  ...fixedWidthProps(160),
});

type ActionCellData = {
  tenderId: number;
  decisionStatus: ActiveDecisionStatus;
};

export const actions: () => MRT_ColumnDef<TenderWithListData> = () => ({
  header: '',
  id: TableColumn.ACTIONS,
  accessorFn: tender => ({
    tenderId: tender.id,
    decisionStatus: tender.interaction?.decisionStatus,
  }),
  Cell: ({ cell }) => {
    const { tenderId, decisionStatus } = cell.getValue<ActionCellData>();
    return <DecisionActionButton tenderId={tenderId} status={decisionStatus} />;
  },
  ...fixedWidthProps(50),
});

export const answeredAt: ColumnType = t => ({
  header: t('cells.answeredAt.title'),
  id: TableColumn.ANSWERED_AT,
  accessorFn: tender => tender.interaction?.answeredAt,
  Cell: ({ cell }) => {
    const answeredAt = cell.getValue<string>();
    return formatDateInParisTZ(answeredAt);
  },
  ...fixedWidthProps(140),
});

export const summarySheetRecap: ColumnType = t => ({
  header: t('cells.summarySheetRecap.title'),
  id: TableColumn.SUMMARY_SHEET_RECAP,
  accessorFn: tender => ({
    tenderId: tender.id,
    summarySheetRAGStatus: tender.interaction?.summarySheetRAGStatus,
    ragGenerability: tender.ragGenerability,
  }),
  Cell: ({ cell }) => {
    const { tenderId, summarySheetRAGStatus, ragGenerability } =
      cell.getValue<SummarySheetRecapCellData>();

    return (
      <SummarySheetRecapCell
        tenderId={tenderId}
        summarySheetRAGStatus={summarySheetRAGStatus}
        ragGenerability={ragGenerability}
      />
    );
  },
  ...fixedWidthProps(74),
});
