import type { Document } from '../../../../../shared/entities/Document';
import { DocumentType } from '../../../../../shared/entities/Document';
import { naturalCompare } from '../../../../../shared/utils/strings';

export const sortDocumentsPerType = (documents: Document[]): Document[] => {
  const typeOrder = {
    [DocumentType.RC]: 1,
    [DocumentType.AVIS]: 2,
    [DocumentType.DCE]: 3,
    [DocumentType.COMPLEMENT]: 4,
    [DocumentType.DUME]: 5,
  };
  const sortedDocuments = [...documents];

  return sortedDocuments.sort((a, b) => {
    const orderA = typeOrder[a.type];
    const orderB = typeOrder[b.type];

    if (orderA !== orderB) {
      return orderA - orderB;
    }

    return naturalCompare(a.prettyName, b.prettyName);
  });
};
