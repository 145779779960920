import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import { Box, Loader, MultiSelect, Stack, Text } from '@mantine/core';

import {
  getCpvByCode,
  searchCPV,
} from '../../../../../shared/api/magellan/cpv';
import type { Cpv } from '../../../../../shared/entities/Cpv';
import { removeTrailingZeros } from '../../../../../shared/utils/strings';
import { useTranslation } from 'react-i18next';

interface CPVItemProps extends ComponentPropsWithoutRef<'div'> {
  code: string;
  title: string;
  value: string;
  label: string;
}

const SelectCPVItem = forwardRef<HTMLDivElement, CPVItemProps>(
  (props: CPVItemProps, ref) => {
    const { code, title, ...others } = props;
    return (
      <Box ref={ref} {...others}>
        <Text truncate>
          {removeTrailingZeros(code, 2)} - {title}
        </Text>
      </Box>
    );
  },
);

type CPVMultiselectProps = {
  value: string[];
  onChange: (values: string[]) => void;
  label: string;
};

export default function CPVMultiselect({
  value,
  onChange,
  label,
}: CPVMultiselectProps) {
  const [CPVs, setCPVs] = useState<CPVItemProps[]>([]);
  const [CPVsSelected, setCPVsSelected] = useState<Cpv[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation('stream');

  const handleValueChanges = (values: string[]) => {
    const CPVsFromValues = values
      .map(code => CPVs.find(CPV => CPV.code === code))
      .filter(Boolean) as Cpv[];

    setCPVsSelected(CPVsFromValues);
    setValues(values);
    onChange(values);
  };

  useEffect(() => {
    const fetchCPVs = async () => {
      const fetchedCPVs = await searchCPV(searchValue);
      const combinedCPVs = [...fetchedCPVs, ...CPVsSelected];

      const CPVsWithValue = combinedCPVs.map(CPV => ({
        ...CPV,
        label: `${removeTrailingZeros(CPV.code, 2)} - ${CPV.title}`,
        value: CPV.code,
      }));

      setCPVs(CPVsWithValue);
      setIsLoading(false);
    };

    if (searchValue.length > 0) {
      setIsLoading(true);
      fetchCPVs();
    }
  }, [CPVsSelected, searchValue]);

  useEffect(() => {
    const setInitialValues = async (initialCPVCodes: string[]) => {
      const CPVs: Cpv[] = await Promise.all(
        initialCPVCodes.map(async code => {
          return await getCpvByCode(code);
        }),
      );
      const CPVsItem = CPVs.map(CPV => ({
        ...CPV,
        label: `${removeTrailingZeros(CPV.code, 2)} - ${CPV.title}`,
        value: CPV.code,
      }));

      setCPVs(CPVsItem);
      setCPVsSelected(CPVs);
      setValues(initialCPVCodes);
    };

    if (value && Array.isArray(value)) {
      setInitialValues(value as string[]);
    }
  }, [value]);

  const nothingFound = isLoading ? <Loader size="sm" /> : t('cpv.cpvMultiselectNothingFound');
  return (
    <Stack spacing="00">
      <Text variant="sm" fw="500" c="gray.9">
        {label}
      </Text>
      <MultiSelect
        placeholder={t('cpv.cpvMultiselectPlaceholder')}
        radius="md"
        searchable
        value={values}
        onChange={handleValueChanges}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        limit={30}
        nothingFound={
          searchValue.length < 2
            ? t('cpv.cpvMultiselectStartTyping')
            : nothingFound
        }
        itemComponent={SelectCPVItem}
        data={CPVs}
        maxDropdownHeight={160}
        filter={(_value, selected) => {
          return !selected;
        }}
      />
    </Stack>
  );
}
