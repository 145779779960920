import { Group, Stack, Text } from '@mantine/core';

import type { DataPointSource } from '../../entities/DataPointMeta';
import { useSourceSidePanel } from '../../hooks/useSourceSidePanel';
import { SourceButton } from './SourceButton';

export function SourcesSection({
  sources,
  tenderId,
}: {
  sources: DataPointSource[];
  tenderId: number;
}) {
  const { openPanel, panelContext } = useSourceSidePanel({
    tenderId,
    sources,
  });

  const handleClick = (source: DataPointSource) => {
    if (!panelContext) {
      openPanel(source);
    }
    panelContext?.updateData({
      ...panelContext?.data,
      selectedSource: source,
    });
  };

  return (
    <Stack spacing="02">
      <Text variant="xs" fw={500} c="gray.4">
        Sources
      </Text>
      <Group spacing="02">
        {sources.map((source, index) => (
          <SourceButton
            key={source.docFilePath + index}
            onClick={() => handleClick(source)}
            label={formatSourceLabel(source)}
            isActive={
              source.originalChunkExtract ===
              panelContext?.data.selectedSource?.originalChunkExtract
            }
          />
        ))}
      </Group>
    </Stack>
  );
}

export function formatSourceLabel(source: DataPointSource) {
  return source.pageNumber
    ? `${source.docFilePath} - Page ${source.pageNumber}`
    : source.docFilePath;
}
