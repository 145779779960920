import { Text, createStyles } from '@mantine/core';
import type { ContextModalProps } from '@mantine/modals';

import { IconCheck } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../UI/Button/Button';

const useStyles = createStyles(theme => ({
  container: {
    padding: theme.spacing['04'],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing['04'],
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing['04'],
  },
  icon: {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    borderRadius: '100%',
    border: `2px solid ${theme.colors.primary[2]}`,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.primary[5],
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing['01'],
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing['02'],
    justifyContent: 'flex-end',
  },
}));

type SaveCriteriaModalProps = ContextModalProps<{
  onCancel: () => void;
  onConfirm: () => void;
}>;
export const SaveCriteriaModal = ({
  innerProps,
  context,
  id,
}: SaveCriteriaModalProps) => {
  const { t } = useTranslation(['summarySheet']);

  const { classes } = useStyles();
  const handleCancel = () => {
    innerProps.onCancel();
    context.closeModal(id);
  };

  const handleConfirm = () => {
    innerProps.onConfirm();
    context.closeModal(id);
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.icon}>
          <IconCheck />
        </div>
        <div className={classes.message}>
          <Text variant="md" fw={500} c="gray.9">
            {t('criteriaSidepanel.confirmationModal.title')}
          </Text>
          <Text variant="sm" fw={400} c="gray.5">
            {t('criteriaSidepanel.confirmationModal.message')}
          </Text>
        </div>
      </div>
      <div className={classes.buttons}>
        <Button variant="subtle" size="md" color="gray" onClick={handleCancel}>
          {t('criteriaSidepanel.confirmationModal.cancel')}
        </Button>
        <Button variant="filled" size="md" onClick={handleConfirm}>
          {t('criteriaSidepanel.confirmationModal.confirm')}
        </Button>
      </div>
    </div>
  );
};
