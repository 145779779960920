import { modals } from '@mantine/modals';

import type { PanelContextValue } from '../../../../../shared/components/UI/Sidepanel/SidepanelInternalData.provider';

import type { CriteriaSidepanelContext } from '../components/summarySheetPanel/CriteriaSidepanelContent';
import { useReplaceAllCriteriaMutation } from './useReplaceAllCriteriaMutation.hook';

export function useSaveCriteriaConfirmationModal() {
  const replaceAllCriteriaMutation = useReplaceAllCriteriaMutation();

  const askUserToSaveBeforeClosingPanel = (
    context: PanelContextValue<CriteriaSidepanelContext>,
  ) => {
    if (!context.data.hasChanges) {
      return true;
    }
    return new Promise<boolean>(resolve => {
      modals.openContextModal({
        modal: 'saveCriteria',
        padding: 0,
        radius: 'lg',
        size: '483px',
        innerProps: {
          onCancel: () => resolve(true),
          onConfirm: () => {
            const criteria = context.data.criteria;
            const hasErrors = context.data.validateCriteria(criteria);

            if (hasErrors) {
              return resolve(false);
            }
            replaceAllCriteriaMutation.mutate(criteria);
            resolve(true);
          },
        },
      });
    });
  };

  return { askUserToSaveBeforeClosingPanel };
}
