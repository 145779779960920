import type { Document } from '../../../shared/entities/Document';
import { naturalCompare } from '../../../shared/utils/strings';

export const sortDocumentsPerLot = (documents: Document[]) => {
  const sections: { [key in Document['section']]: Document[] } = {
    Général: [],
  };

  const sortedDocuments = [...documents];
  sortedDocuments.sort((a, b) => naturalCompare(a.section, b.section));
  sortedDocuments.forEach(document => {
    sections[document.section] ||= [];
    sections[document.section].push(document);
  });

  return sections;
};
