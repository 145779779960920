import { Suspense, useState } from 'react';

import { Group, Stack, Text } from '@mantine/core';

import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';

import { Error } from '../../shared/components/UI/Error/Error.tsx';
import { NAVBAR_HEIGHT } from '../../shared/components/UI/Navbar/Navbar';
import { TabsComponents } from '../../shared/components/UI/Tabs/Tabs';

import * as SkeletonLoader from '../../features/pipeline/components/decisionTables/components/SkeletonLoader';
import { Header } from '../../features/pipeline/components/Header';
import { DecisionTables } from '../../features/pipeline/components/decisionTables/components/DecisionTables';
import { PipelineFilters } from '../../features/pipeline/components/decisionTables/components/PipelineFilters';
import { PipelineRenewalsFilters } from '../../features/pipeline/components/decisionTables/components/PipelineRenewalsFilters';
import { RenewalDecisionTables } from '../../features/pipeline/components/decisionTables/components/RenewalDecisionTables';
import { useSetPageTitle } from '../../shared/hooks/useSetPageTitle.hook';

type TabsValue = 'opportunity' | 'renewal';

const DEFAULT_TAB = 'opportunity';

export default function PipelinePage() {
  useSetPageTitle('Pipeline');

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlTabsValue = (queryParams.get('tab') as TabsValue) || DEFAULT_TAB;

  const [tabValue, setTabValue] = useState<TabsValue>(urlTabsValue);

  const Tabs = TabsComponents<TabsValue>();

  return (
    <Stack
      w="100%"
      h={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
      p={0}
      spacing={0}
      sx={{
        background: 'linear-gradient(180deg, #EFF8FF -2.79%, #FFFFFF 2.09%)',
      }}
    >
      <Tabs
        py="03"
        defaultTab={DEFAULT_TAB}
        value={tabValue}
        onTabChange={(value: TabsValue) => setTabValue(value)}
        sx={{ zIndex: 1 }}
        h={undefined}
      >
        <Tabs.Button value="opportunity" label="Opportunités" />
        <Tabs.Button
          value="renewal"
          label={
            <Group spacing="xs">
              <Text
                variant="sm"
                fw={tabValue === 'renewal' ? '500' : '400'}
                c={tabValue === 'renewal' ? 'gray.9' : 'gray.6'}
              >
                Renouvellements
              </Text>
              <Text
                variant="sm"
                fw={600}
                c="primary.8"
                px="02"
                sx={theme => ({
                  background: theme.colors.primary[1],
                  borderRadius: theme.radius.lg,
                })}
              >
                Beta
              </Text>
            </Group>
          }
        />
      </Tabs>
      <Sentry.ErrorBoundary fallback={<Error />}>
        <Suspense fallback={<SkeletonLoader.FullPage />}>
          {tabValue === 'renewal' ? (
            <div>
              <PipelineRenewalsFilters />
              <RenewalDecisionTables />
            </div>
          ) : (
            <div>
              <Header />
              <PipelineFilters />
              <DecisionTables />
            </div>
          )}
        </Suspense>
      </Sentry.ErrorBoundary>
    </Stack>
  );
}
