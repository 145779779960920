import { useSuspenseQuery } from '@tanstack/react-query';

import { getDecisionCounts } from '../../../shared/api/magellan/interaction';

export function useDecisionCountsQuery() {
  return useSuspenseQuery({
    queryKey: [getDecisionCounts.name],
    queryFn: getDecisionCounts,
  });
}
