import { useMemo } from 'react';

import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';

import { useTranslation } from 'react-i18next';

import * as columnDefs from '../utils/columnDefinitions';
import * as SkeletonLoader from './SkeletonLoader';
import { useNavigateWithControl } from '../../../../../shared/hooks/useNavigateWithControl.hook';
import { useRenewalTendersWithInteractionQuery } from '../../../hooks/useRenewalTendersWithInteractionQuery.hook';
import { makeRenewalDecisionTableConfig } from '../utils/tableConfig';
import { InfiniteScroll } from './InfiniteScroll';

export function RenewalDecisionTables() {
  const { t } = useTranslation('pipeline');

  const columns = useMemo(
    () => [
      columnDefs.summarySheetRecap(t),
      columnDefs.opportunity(t),
      columnDefs.status(t),
      columnDefs.responseDeadline(t),
      columnDefs.owner(t),
      columnDefs.contributions(t),
      columnDefs.actions(),
    ],
    [t],
  );

  const renewalTendersWithInteractionQuery =
    useRenewalTendersWithInteractionQuery();

  const tableData = renewalTendersWithInteractionQuery.data?.pages.flatMap(
    pageData => pageData.tenders,
  );

  const navigateWithControl = useNavigateWithControl();

  const tableConfig = makeRenewalDecisionTableConfig({
    onRowClick: (event, tenderId) =>
      navigateWithControl(event, `/notices/${tenderId}`),
  });

  const table = useMantineReactTable({
    columns,
    data: tableData ?? [],
    ...tableConfig,
  });

  return tableData ? (
    <>
      <MantineReactTable table={table} />
      <InfiniteScroll
        isFetching={renewalTendersWithInteractionQuery.isFetching}
        fetchNextPage={renewalTendersWithInteractionQuery.fetchNextPage}
        hasNextPage={renewalTendersWithInteractionQuery.hasNextPage}
      />
    </>
  ) : (
    <SkeletonLoader.Table />
  );
}
