
import { Group, Switch, Text, Box, Stack } from '@mantine/core';
import { IconInfoSmall, IconAlertTriangle } from '@tabler/icons-react';
import TooltipWithIcon from './TooltipWithIcon';
import { useTranslation } from 'react-i18next';


type TendersWithoutLocationSwitchContainerProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export function TendersWithoutLocationSwitchContainer({
  value,
  onChange,
}: TendersWithoutLocationSwitchContainerProps) {
  const { t } = useTranslation('stream');

  return (
    <Stack spacing="02">
      <Group w="100%" spacing="02" noWrap>
        <Group noWrap spacing="01">
          <Text variant="sm" fw="400" c="gray.5">
            {t('location.includeTendersWithoutLocation')}
          </Text>
          <TooltipWithIcon
            color="gray.4"
            icon={<Box w={"05"} h={"05"}>
              <IconInfoSmall size={23} stroke={2} />
            </Box>
            }
            content={t('location.includeTendersWithoutLocationSwitchTooltip')}
          />
        </Group>
        <TendersWithoutLocationSwitch
          value={value}
          onChange={onChange}
        />
      </Group>
      {value === false && (
        <TendersWithoutLocationSwitchWarning />
      )}
    </Stack>
  );
}

function TendersWithoutLocationSwitch({
  value,
  onChange,
}: TendersWithoutLocationSwitchContainerProps) {

  return (
    <Group
      noWrap
      position="apart"
      onClick={() => onChange(!value)}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Switch
        checked={value}
        onClick={e => {
          e.stopPropagation();
        }}
      />
    </Group>
  );
}

function TendersWithoutLocationSwitchWarning() {
  const { t } = useTranslation('stream');

  return (
    <Group spacing={"02"}>
      <Box>
        <Text c="yellow.9" size="xs"><IconAlertTriangle size={16} stroke={2} /></Text>
      </Box>
      <Box>
        <Text c="yellow.9" size="xs">
          {t('location.includeTendersWithoutLocationSwitchWarning')}
        </Text>
      </Box>
    </Group>
  );
}
