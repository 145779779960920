import { ActionIcon, Group, Text, ThemeIcon } from '@mantine/core';

import {
  IconCalendarDue,
  IconCircleDashed,
  IconUserCircle,
  IconX,
} from '@tabler/icons-react';

import * as dateUtils from '../../../../../shared/utils/dates';
import { DecisionStatus } from '../../../../../shared/entities/Interaction';
import type { TenderStatus } from '../../../../../shared/entities/Tender';
import { useUsersQuery } from '../../../../../shared/hooks/useUsersQuery.hook';
import { useTabsStatus } from '../../../hooks/useTabs.hook';
import { TableColumn } from '../utils/columnDefinitions';
import { dateFilterToValue, tenderStatusToLabel } from '../utils/utils';

export function Owner({
  values = [],
  removeFilter,
}: {
  values?: string[];
  removeFilter: (columnId: TableColumn.OWNER) => void;
}) {
  const { data: users } = useUsersQuery();

  if (values.length === 0) {
    return null;
  }

  const displayValue = values
    .map(userId => {
      const user = users.find(user => user.id === userId);
      return user ? `${user.firstName} ${user.lastName}` : userId;
    })
    .join(', ');

  return (
    <FilterTag
      filterLabel="Responsable"
      icon={<IconUserCircle size={16} />}
      value={displayValue}
      onClose={() => removeFilter(TableColumn.OWNER)}
    />
  );
}

export function Status({
  values = [],
  removeFilter,
}: {
  values?: TenderStatus[];
  removeFilter: (columnId: TableColumn.STATUS) => void;
}) {
  if (values.length === 0) {
    return null;
  }

  return (
    <FilterTag
      filterLabel="Status"
      icon={<IconCircleDashed size={16} />}
      value={values.map(tenderStatusToLabel).join(', ')}
      onClose={() => removeFilter(TableColumn.STATUS)}
    />
  );
}

export function ResponseDeadline({
  value,
  removeFilter,
}: {
  value?: string[];
  removeFilter: (columnId: TableColumn.RESPONSE_DEADLINE) => void;
}) {
  const { activeTabStatus } = useTabsStatus();

  const responseDeadline = dateFilterToValue(value);

  if (
    !responseDeadline ||
    ![DecisionStatus.TO_ANALYZE, DecisionStatus.GO].includes(activeTabStatus)
  ) {
    return null;
  }

  return (
    <FilterTag
      filterLabel="À déposer avant le"
      icon={<IconCalendarDue size={16} />}
      value={dateUtils.parseDateToFrLocaleDateString(responseDeadline)}
      onClose={() => removeFilter(TableColumn.RESPONSE_DEADLINE)}
    />
  );
}

type FilterTagProps = {
  icon: React.ReactElement;
  filterLabel: string;
  value: string;
  onClose: () => void;
};

function FilterTag({ icon, filterLabel, value, onClose }: FilterTagProps) {
  return (
    <Group
      noWrap
      spacing="01"
      px="02"
      py="00"
      maw={400}
      sx={theme => ({
        borderRadius: theme.radius.md,
        backgroundColor: theme.colors.primary[1],
      })}
    >
      <ThemeIcon variant="light" size="sm" bg="primary.1">
        {icon}
      </ThemeIcon>
      <Text variant="sm" fw="500">
        {filterLabel}
      </Text>
      <Text variant="sm" fw="400" truncate>
        {value}
      </Text>
      <ActionIcon variant="transparent" size={16} onClick={() => onClose()}>
        <IconX size={16} />
      </ActionIcon>
    </Group>
  );
}
