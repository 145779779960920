import { forwardRef } from 'react';

import { ActionIcon, Flex, Stack, Text } from '@mantine/core';

import { IconChevronsRight } from '@tabler/icons-react';

import type { PanelContextValue } from './SidepanelInternalData.provider';
import { SidepanelInternalDataProvider } from './SidepanelInternalData.provider';

export type SidepanelProps<T> = {
  id: string;
  header: React.ReactNode | string;
  content: React.ReactNode;
  closePanel: () => void;
  onBeforeClose?: (context: PanelContextValue<T>) => Promise<boolean> | boolean;
  onClose?: () => void;
  initialData?: T;
  onDataUpdate: (newData: T) => void;
};

export const Sidepanel = forwardRef(
  <T,>(
    {
      header,
      content,
      closePanel,
      initialData,
      onDataUpdate,
    }: SidepanelProps<T>,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    return (
      <SidepanelInternalDataProvider
        initialData={initialData}
        onDataUpdate={onDataUpdate}
      >
        <Stack
          ref={ref}
          p={0}
          h="100%"
          bg="white"
          sx={theme => ({
            gap: 0,
            borderLeft: `1px solid ${theme.colors.gray[1]}`,
            boxShadow: theme.shadows.sm,
          })}
        >
          <Flex p="03" py="02">
            <Flex gap="02" w="100%" align="center">
              <ActionIcon onClick={closePanel} color="gray.6">
                <IconChevronsRight size={16} />
              </ActionIcon>

              <Flex w="100%">
                {typeof header === 'string' ? (
                  <Text variant="sm" fw="400" c="gray.9">
                    {header}
                  </Text>
                ) : (
                  header
                )}
              </Flex>
            </Flex>
          </Flex>

          {content}
        </Stack>
      </SidepanelInternalDataProvider>
    );
  },
);
