import type { MRT_ColumnDef } from 'mantine-react-table';

import type { Cpv } from '../../../../../shared/entities/Cpv';
import type { TenderWithRagGenerability } from '../../../../../shared/entities/Tender';
import type { ActionCellData } from './cells/ActionCell';
import { ActionCell } from './cells/ActionCell';
import { AmountCell, type AmountCellData } from './cells/AmountCell';
import { DateCell } from './cells/DateCell';
import type { DateCellData } from './cells/DateCell';
import { DecisionCell } from './cells/DecisionCell';
import type { DurationCellData } from './cells/DurationCell';
import { DurationCell } from './cells/DurationCell';
import { OpportunityCell } from './cells/OpportunityCell';
import type { StatusCellData } from './cells/StatusCell';
import { StatusCell } from './cells/StatusCell';

export enum TableColumn {
  OPPORTUNITY = 'opportunity',
  STATUS = 'status',
  DATES = 'dates',
  AMOUNT = 'amount',
  DURATION = 'duration',
  DECISION_STATUS = 'decisionStatus',
  ACTIONS = 'actions',
}

const fixedWidthProps = (width?: number) => ({
  size: width,
  maxSize: width,
  minSize: width,
  mantineTableBodyCellProps: {
    sx: { flex: `0 0 ${width}px`, flexGrow: 0 },
  },
  mantineTableHeadCellProps: {
    sx: { flex: `0 0 ${width}px`, flexGrow: 0 },
  },
});

export type OpportunityCellData = {
  tenderTitle: string;
  cpvs: Cpv[];
};

export const oppotunityColumn: MRT_ColumnDef<TenderWithRagGenerability> = {
  header: 'Opportunité',
  id: TableColumn.OPPORTUNITY,
  accessorFn: tender => ({
    tenderTitle: tender.title,
    cpvs: tender.cpvs,
  }),
  Cell: ({ cell }) => <OpportunityCell cell={cell} />,
};

export const statusColumn: MRT_ColumnDef<TenderWithRagGenerability> = {
  header: 'Status',
  id: TableColumn.STATUS,
  accessorFn: tender =>
    ({
      status: tender.status,
      isRenewal: !!tender.markedAsRenewalAt,
    }) as StatusCellData,
  enableColumnFilter: false,
  Cell: StatusCell,
  ...fixedWidthProps(140),
};

export const datesColumn: MRT_ColumnDef<TenderWithRagGenerability> = {
  header: 'Date',
  id: TableColumn.DATES,
  accessorFn: tender =>
    ({
      publicationDate: tender.publicationDate,
      responseDeadline: tender.responseDeadline,
    }) as DateCellData,
  Cell: DateCell,
  ...fixedWidthProps(260),
};

export const amountColumn: MRT_ColumnDef<TenderWithRagGenerability> = {
  header: 'Montant',
  id: TableColumn.AMOUNT,
  accessorFn: tender =>
    ({
      amount: tender.estimatedValueInEur,
      isLlmEnriched: tender.isEstimatedValueInEurLLMEnriched,
    }) as AmountCellData,
  Cell: AmountCell,
  ...fixedWidthProps(120),
};

export const durationColumn: MRT_ColumnDef<TenderWithRagGenerability> = {
  header: 'Durée',
  id: TableColumn.DURATION,
  accessorFn: tender =>
    ({
      duration: tender.durationInMonth,
      isLlmEnriched: tender.isDurationInMonthLLMEnriched,
    }) as DurationCellData,
  Cell: DurationCell,
  ...fixedWidthProps(100),
};

export const decisionStatusColumn: MRT_ColumnDef<TenderWithRagGenerability> = {
  header: 'Décision',
  id: TableColumn.DECISION_STATUS,
  accessorFn: tender =>
    tender.markedAsRenewalAt
      ? tender.interaction?.decisionRenewalStatus
      : tender.interaction?.decisionStatus,
  Cell: DecisionCell,
  ...fixedWidthProps(120),
};

export const getActionsColumn = (
  buyerInfiniteQueryKey: string,
): MRT_ColumnDef<TenderWithRagGenerability> => ({
  header: '',
  id: TableColumn.ACTIONS,
  accessorFn: tender =>
    ({
      tenderId: tender.id,
      decisionStatus: tender.interaction?.decisionStatus,
      renewalStatus: tender.interaction?.decisionRenewalStatus,
      buyerInfiniteQueryKey,
      isRenewal: !!tender.markedAsRenewalAt,
      ragGenerability: tender.ragGenerability,
    }) as ActionCellData,
  Cell: ActionCell,
  ...fixedWidthProps(50),
});
