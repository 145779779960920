import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import {
  Box,
  Group,
  Loader,
  MultiSelect,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';

import { BuyerLogo } from '../../../../../shared/components/UI/BuyerLogo/BuyerLogo';

import {
  getBuyerById,
  getBuyersByName,
} from '../../../../../shared/api/magellan/buyer';
import type { MinimalBuyerDTO } from '../../../../../shared/api/magellan/buyer/dto';
import type { StreamFormValues } from '../../../../../shared/entities/StreamFilterSettings';
import { useTranslation } from 'react-i18next';

// not using camel case because the property will be spread to inernat components inside mantine's multiselect and React will throw errors like so:
// React does not recognize the `normalizedName` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `normalizedname` instead. If you accidentally passed it from a parent component, remove it from the DOM element.

interface BuyerItemProps extends ComponentPropsWithoutRef<'div'> {
  normalizedname: string;
  originalname: string;
  postalCode?: string;
  logourl?: string;
  tendercount?: number;
  value: string;
  label: string;
  id: string;
}

const SelectBuyerItem = forwardRef<HTMLDivElement, BuyerItemProps>(
  (props: BuyerItemProps, ref) => {
    const { originalname, postalCode, logourl, tendercount, id, ...others } =
      props;
    const postalCodeFormater = postalCode ? `- ${postalCode}` : '';
    return (
      <Box ref={ref} {...others}>
        <Group position="apart">
          <Group spacing="xs">
            <BuyerLogo
              buyerId={Number(id)}
              logoURL={logourl}
              size="sm"
              radius="sm"
            />
            <Text
              variant="sm"
              fw={500}
              c="dark.7"
              truncate
            >{`${originalname} ${postalCodeFormater}`}</Text>
          </Group>
          <Tooltip
            label={
              <Text variant="xs" fw="600">
                {tendercount} appels d'offres
              </Text>
            }
            position="bottom"
            withArrow
            arrowSize={6}
            color="primary.6"
            multiline
          >
            <Text pr={8} variant="xs" fw={500} c="dark.2">
              {tendercount}
            </Text>
          </Tooltip>
        </Group>
      </Box>
    );
  },
);

type BuyerMultiselectProps = {
  form: UseFormReturnType<
    StreamFormValues,
    (values: StreamFormValues) => StreamFormValues
  >;
};

export const BuyerMultiselect = ({ form }: BuyerMultiselectProps) => {
  const { t } = useTranslation('stream');
  const [buyers, setBuyers] = useState<BuyerItemProps[]>([]);
  const [buyersSelected, setBuyersSelected] = useState<MinimalBuyerDTO[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleValueChanges = (values: string[]) => {
    const buyersFromValues = values
      .map(id => {
        const buyerItem = buyers.find(buyer => buyer.id === id);
        if (!buyerItem) {
          return undefined;
        }
        return {
          id,
          normalizedName: buyerItem?.normalizedname,
          originalName: buyerItem.originalname,
          postalCode: buyerItem.postalCode,
        };
      })
      .filter(Boolean) as MinimalBuyerDTO[];

    setBuyersSelected(buyersFromValues);
    setValues(values);
    form.setValues({ ...form.values, buyerIds: values });
  };

  useEffect(() => {
    const fetchBuyers = async () => {
      const fetchedBuyers = await getBuyersByName(searchValue);
      const combinedBuyers = [...fetchedBuyers, ...buyersSelected];

      const buyersWithValue = combinedBuyers.map(buyer => ({
        normalizedname: buyer.normalizedName,
        originalname: buyer.originalName,
        postalCode: buyer.postalCode,
        value: buyer.id,
        label: buyer.normalizedName,
        id: buyer.id,
        logourl: buyer.logoURL,
        tendercount: buyer.tendersCount,
      }));

      setBuyers(buyersWithValue);
      setIsLoading(false);
    };

    if (searchValue.length > 1) {
      setIsLoading(true);
      fetchBuyers();
    }
  }, [buyersSelected, searchValue]);

  useEffect(() => {
    const setInitialValues = async (initialBuyerIds: string[]) => {
      const buyers: MinimalBuyerDTO[] = await Promise.all(
        initialBuyerIds.map(async id => {
          return await getBuyerById(id);
        }),
      );
      const buyersItem = buyers.map(buyer => ({
        id: buyer.id,
        normalizedname: buyer.normalizedName,
        originalname: buyer.originalName,
        postalCode: buyer.postalCode,
        value: buyer.id,
        label: buyer.normalizedName,
        logourl: buyer.logoURL,
        tendercount: buyer.tendersCount,
      }));
      setBuyers(buyersItem);
      setBuyersSelected(buyers);
      setValues(initialBuyerIds);
    };
    if (form.values.buyerIds && form.values.buyerIds.length) {
      setInitialValues(form.values.buyerIds);
    }
  }, [form.values.buyerIds]);

  const nothingFound = isLoading ? (
    <Loader size="sm" />
  ) : (
    t('buyer.nothingFound')
  );
  return (
    <Stack spacing="00">
      <Text variant="sm" fw="500" c="gray.9">
        {t('buyer.buyerToInclude')}
      </Text>
      <MultiSelect
        placeholder={t('buyer.placeholder')}
        radius="md"
        styles={theme => ({
          item: {
            '&:hover': {
              backgroundColor: theme.colors.gray[1],
            },
            '&[data-selected]': {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: theme.colors.gray[1],
              },
            },
          },
        })}
        searchable
        value={values}
        onChange={handleValueChanges}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        limit={30}
        nothingFound={
          searchValue.length < 2
            ? t('buyer.startTyping')
            : nothingFound
        }
        itemComponent={SelectBuyerItem}
        data={buyers}
        maxDropdownHeight={160}
        filter={() => true}
      />
    </Stack>
  );
};
