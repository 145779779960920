import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { useDisclosure } from '@mantine/hooks';

import type { SidepanelProps } from '../components/UI/Sidepanel/Sidepanel';
import type { PanelContextValue } from '../components/UI/Sidepanel/SidepanelInternalData.provider';
import { SidepanelLayout } from '../components/UI/Sidepanel/SidepanelLayout';

interface SidepanelContextType {
  togglePanel: <T>(content: Omit<SidepanelProps<T>, 'onDataUpdate'>) => void;
  closePanel: (id: string) => void;
  getPanelContext: <T>(id: string) => PanelContextValue<T> | undefined;
  closeAllPanels: () => void;
}

export type PanelState<T> = {
  props: SidepanelProps<T>;
  context: PanelContextValue<T>;
};

type MinimalSidepanelProps<T> = Omit<
  SidepanelProps<T>,
  'id' | 'closePanel' | 'onBeforeClose' | 'onClose' | 'onDataUpdate'
>;

const SidepanelContext = createContext<SidepanelContextType | undefined>(
  undefined,
);

export function SidepanelProvider({ children }: { children: ReactNode }) {
  const [opened, { open, close }] = useDisclosure(false);
  const [panels, setPanels] = useState<PanelState<any>[]>([]);

  const togglePanel = <T,>(
    newPanel: Omit<SidepanelProps<T>, 'onDataUpdate'>,
  ) => {
    const existingPanel = panels.find(panel => panel.props.id === newPanel.id);
    if (existingPanel) {
      return closePanel(existingPanel.props.id);
    }

    const context: PanelContextValue<T> = {
      data: newPanel.initialData as T,
      updateData: <T,>(newData: T) => {
        setPanels(prev =>
          prev.map(panel =>
            panel.props.id === newPanel.id
              ? {
                  ...panel,
                  context: { ...panel.context, data: newData },
                }
              : panel,
          ),
        );
      },
    };

    const newPanelState: PanelState<T> = {
      props: {
        ...newPanel,
        onDataUpdate: context.updateData,
      },
      context,
    };

    setPanels(prev => [...prev, newPanelState]);
    if (!opened) {
      open();
    }
  };

  const closePanel = async (id: string) => {
    setPanels(prev => {
      const panelIndex = prev.findIndex(panel => panel.props.id === id);
      if (panelIndex === -1) {
        return prev;
      }

      const panel = prev[panelIndex];

      // Close all panels that come after this one
      for (const p of prev.slice(panelIndex + 1)) {
        closePanel(p.props.id);
      }

      // Handle panel closing with beforeClose check
      if (panel.props.onBeforeClose) {
        const checkBeforeClose = async () => {
          const shouldClose = await panel.props.onBeforeClose?.(panel.context);
          if (shouldClose) {
            panel.props.onClose?.();
            setPanels(current => current.filter(p => p.props.id !== id));
          }
        };
        checkBeforeClose();
        return prev;
      }

      // Regular panel closing
      panel.props.onClose?.();
      return prev.slice(0, panelIndex);
    });
  };

  const closeAllPanels = () => {
    panels.forEach(panel => panel.props.closePanel());
  };

  const getPanelContext = <T,>(
    id: string,
  ): PanelContextValue<T> | undefined => {
    return panels.find(panel => panel.props.id === id)?.context;
  };

  useEffect(() => {
    if (!panels.length && opened) {
      close();
    }
  }, [close, opened, panels]);

  return (
    <SidepanelContext.Provider
      value={{
        togglePanel,
        closePanel,
        getPanelContext,
        closeAllPanels,
      }}
    >
      {children}
      <SidepanelLayout
        opened={opened}
        closeAllPanels={closeAllPanels}
        panels={panels}
      />
    </SidepanelContext.Provider>
  );
}
/**
 * Custom hook to manage sidepanel behavior within a `SidepanelProvider` context. Calling this will create a new sidepanel and give you a way to open, update and close it.
 *
 * @param {Object} [options] - Configuration options for the sidepanel.
 * @param {() => void} [options.onClose] - Optional callback to execute when the sidepanel is closed.
 * @param {(context: PanelContextValue<T>) => Promise<boolean> | boolean} [options.onBeforeClose] - Optional async callback to execute before closing the sidepanel.
 *   Should return a promise resolving to `true` if the sidepanel can close, or `false` to prevent closing.
 *
 * @returns {Object} The sidepanel management object.
 * @returns {(content: MinimalSidepanelProps) => void} return.togglePanel - Function to toggle the sidepanel with the specified content.
 * @returns {(content: MinimalSidepanelProps) => void} return.updatePanel - Function to update the content of the sidepanel.
 * @returns {() => void} return.closePanel - Function to close the sidepanel.
 * @returns {() => PanelContextValue<T>} return.getPanelContext - Function to get the context of the sidepanel.
 */
export function useSidepanel<T>({
  onClose,
  onBeforeClose,
}: {
  onClose?: () => void;
  onBeforeClose?: (context: PanelContextValue<T>) => Promise<boolean> | boolean;
} = {}) {
  const context = useContext(SidepanelContext);
  if (!context) {
    throw new Error('useSidepanel must be used within a SidepanelProvider');
  }
  // using ref to get consistent ID across renders
  const idRef = useRef(Math.random().toString(36).substring(7));

  const id = idRef.current;
  const closePanel = () => {
    context.closePanel(id);
  };

  return {
    togglePanel: (content: MinimalSidepanelProps<T>) =>
      context.togglePanel({
        ...content,
        id,
        closePanel,
        onBeforeClose,
        onClose,
      }),
    closePanel,
    getPanelContext: () => context.getPanelContext<T>(id),
  };
}

export function useCloseAllPanels() {
  const context = useContext(SidepanelContext);
  if (!context) {
    throw new Error(
      'useCloseAllPanels must be used within a SidepanelProvider',
    );
  }
  return context.closeAllPanels;
}
