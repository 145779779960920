import { Group, Stack } from '@mantine/core';

import * as FilterTags from './FilterTags';
import * as Filters from './Filters';
import * as SkeletonLoader from './SkeletonLoader';
import { usePipelineRenewalsFilters } from '../../../hooks/usePipelineRenewalsFilters.hook';
import { useRenewalTendersWithInteractionQuery } from '../../../hooks/useRenewalTendersWithInteractionQuery.hook';
import { TableColumn } from '../utils/columnDefinitions';
import { FiltersWrapper } from './FilterWrapper';
import { GlobalSearchInput } from './GlobalSearchInput';

export function PipelineRenewalsFilters() {
  const { filters, setOwnerFilter, resetFilters, removeFilter, isLoaded } =
    usePipelineRenewalsFilters();

  const renewalTendersWithInteractionQuery =
    useRenewalTendersWithInteractionQuery();

  if (!isLoaded) {
    return <SkeletonLoader.FilterBar />;
  }

  const rowCount =
    renewalTendersWithInteractionQuery.data?.pages[0].pagination.totalResults ??
    0;

  return (
    <Stack mx="06" pt="03" spacing="03">
      <Group spacing={0} position="apart">
        <FiltersWrapper rowCount={rowCount}>
          <Filters.Owner filters={filters} onChange={setOwnerFilter} />

          <Filters.ResetButton filters={filters} resetFilters={resetFilters} />
        </FiltersWrapper>

        <GlobalSearchInput />
      </Group>

      <Group>
        <FilterTags.Owner
          values={filters[TableColumn.OWNER]}
          removeFilter={removeFilter}
        />
      </Group>
    </Stack>
  );
}
