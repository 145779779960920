import { useSuspenseQuery } from '@tanstack/react-query';

import { getUsers } from '../api/magellan/users';

export function useUsersQuery() {
  return useSuspenseQuery({
    queryKey: [getUsers.name],
    queryFn: getUsers,
  });
}
