import * as DocumentSidePanel from '../components/SourcesSection/SourceSidePanel';
import { useSidepanel } from '../contexts/Sidepanel.provider';
import type { DataPointSource } from '../entities/DataPointMeta';
import { useTenderQuery } from './useTenderQuery.hook';

export function useSourceSidePanel({
  sources,
  tenderId,
}: {
  sources: DataPointSource[];
  tenderId: number;
}) {
  const { togglePanel, closePanel, getPanelContext } =
    useSidepanel<DocumentSidePanel.SourceSidepanelContext>({});
  const panelContext = getPanelContext();
  const tenderQuery = useTenderQuery(Number(tenderId));

  const openPanel = (selectedSource: DataPointSource) => {
    const documents = tenderQuery.data?.documents ?? [];
    togglePanel({
      header: <DocumentSidePanel.Header />,
      content: (
        <DocumentSidePanel.Content
          tenderId={Number(tenderId)}
          onClose={closePanel}
        />
      ),
      initialData: {
        sources,
        documents,
        selectedSource,
      },
    });
  };

  return { panelContext, openPanel };
}
