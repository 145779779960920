import { Drawer, Group } from '@mantine/core';

import { useAutoAnimate } from '@formkit/auto-animate/react';

import type { PanelState } from '../../../contexts/Sidepanel.provider';
import { NAVBAR_HEIGHT } from '../Navbar/Navbar';
import { Sidepanel } from './Sidepanel';

type SidepanelLayoutProps = {
  opened: boolean;
  closeAllPanels: () => void;
  panels: PanelState<unknown>[];
};

export function SidepanelLayout({
  opened,
  closeAllPanels,
  panels,
}: SidepanelLayoutProps) {
  const [parent] = useAutoAnimate();

  return (
    <Drawer
      opened={opened}
      position="right"
      onClose={closeAllPanels}
      size="fit-content"
      withCloseButton={false}
      overlayProps={{ opacity: 0.5 }}
      p={0}
      styles={{
        body: {
          padding: 0,
          height: '100%',
          overflow: 'hidden',
        },
        inner: {
          top: NAVBAR_HEIGHT,
        },
        overlay: {
          top: NAVBAR_HEIGHT,
        },
      }}
    >
      <Group ref={parent} maw="100%" noWrap h="100%" spacing={0}>
        {panels.map(panel => (
          <Sidepanel
            {...panel.props}
            initialData={panel.context.data}
            key={panel.props.id}
          />
        ))}
      </Group>
    </Drawer>
  );
}
