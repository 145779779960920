import { Divider, Group, Text } from '@mantine/core';

import { IconFilter } from '@tabler/icons-react';

type FiltersWrapperProps = {
  rowCount: number;
  children: React.ReactNode;
};

export function FiltersWrapper({ rowCount, children }: FiltersWrapperProps) {
  return (
    <Group spacing="02">
      <Text variant="sm" fw="400" c="gray.9" miw="110px">
        {rowCount} opportunités
      </Text>
      <Divider orientation="vertical" h="04" my="auto" />
      <Group spacing="01" c="gray.6">
        <IconFilter size={20} />
        <Text variant="sm" fw="400" c="gray.6">
          Filtrer
        </Text>
      </Group>

      {children}
    </Group>
  );
}
