import * as Sentry from '@sentry/react';

import { Error as ErrorComponent } from '../../../../shared/components/UI/Error/Error.tsx';

import { useNoticeContext } from '../../contexts/Notice.provider';
import { LoadingCard } from '../summarySheet/components/LoadingCard.tsx';
import { SummarySheetCard } from '../summarySheet/components/SummarySheetCard.tsx';
import { TriggerCard } from '../summarySheet/components/TriggerCard.tsx';
import { useGetSummaryCriterionAnswers } from '../summarySheet/hooks/useGetSummaryCriterionAnswers.hook.ts';

export const SummarySheetTab = () => {
  const { tender } = useNoticeContext();
  const {
    data: { summarySheetRAGStatus },
  } = useGetSummaryCriterionAnswers(tender.id);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
      <Content summarySheetRAGStatus={summarySheetRAGStatus} />
    </Sentry.ErrorBoundary>
  );
};

const Content = ({
  summarySheetRAGStatus,
}: {
  summarySheetRAGStatus: string;
}) => {
  switch (summarySheetRAGStatus) {
    case 'NO_SUPPORTED_DOCUMENTS':
    case 'WAITING':
      return <TriggerCard />;
    case 'RUNNING':
      return <LoadingCard />;
    case 'DONE':
      return <SummarySheetCard />;
    case 'FAILED':
    default:
      throw new Error('Summary sheet generation failed');
  }
};
