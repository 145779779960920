import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateInteraction } from '../../../shared/api/magellan/interaction';
import { getTender } from '../../../shared/api/magellan/tender';

export function useUpdateSubscriptionStatusMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      tenderId,
      isSubscribedToTenderUpdateOnBuyerProfile,
    }: {
      tenderId: number;
      isSubscribedToTenderUpdateOnBuyerProfile: boolean;
    }) => {
      return updateInteraction(tenderId, {
        isSubscribedToTenderUpdateOnBuyerProfile,
      });
    },
    onSuccess: (_, { tenderId }) => {
      queryClient.invalidateQueries({
        queryKey: [getTender.name, tenderId],
      });
    },
  });
}
