import { useEffect } from 'react';

import { ScrollArea, Stack } from '@mantine/core';

import { SOURCE_SIDE_PANEL_WIDTH } from '../../../../../../shared/components/SourcesSection/SourceSidePanel';
import { SourcesSection } from '../../../../../../shared/components/SourcesSection/SourcesSection';
import { useUrlHash } from '../../../../../../shared/contexts/UrlHash.provider';
import type { Document } from '../../../../../../shared/entities/Document';
import { useDocumentSidePanel } from '../../../../../../shared/hooks/useDocumentSidePanel';
import { UrlHashTypes } from '../../../../../../shared/utils/navigation/UrlHashBuilder';
import { sortDocumentsPerLot } from '../../../../utils/sortDocumentsPerLot';
import { DocumentsAccordion } from '../../../documents/components/DocumentsAccordion';
import { useGetSummaryCriterionDetail } from '../../hooks/useGetSummaryCriterionDetail.hook';
import { CriterionAnswerType, getAnswerType } from '../../utils/getAnswerType';
import { CriterionElement } from './CriterionElement';
import { EditedAtRow } from './EditedAtRow';
import { SummarySheetCollaboration } from './SummarySheetCollaboration';

const MIN_MARGIN_LEFT_TO_ENABLE_USER_TO_CLICK_OUTSIDE = 20;
type AnswerDetailPanelProps = {
  tenderId: number;
  documents: Document[];
  criterionAnswerId: number;
  closePanel: () => void;
};

export function AnswerDetailPanel({
  tenderId,
  criterionAnswerId,
  documents,
}: AnswerDetailPanelProps) {
  const criterionAnswerDetailQuery = useGetSummaryCriterionDetail(
    tenderId,
    criterionAnswerId,
  );

  const {
    manualAnswerCreatedBy: user,
    manualAnswerCreatedAt: createdAt,
    thread,
    sources,
  } = criterionAnswerDetailQuery.data;

  const answerType = getAnswerType(criterionAnswerDetailQuery.data);
  const hasAnswer = answerType !== CriterionAnswerType.NOT_FOUND;

  const { getReceivedUrlHashParam } = useUrlHash();
  const receivedUrlHashParam = getReceivedUrlHashParam(
    UrlHashTypes.SUMMARY_SHEET_ACTION,
  );

  useEffect(() => {
    if (receivedUrlHashParam) {
      const summarySheetCollaboration = document.getElementById(
        'summarySheetCollaboration',
      );
      if (summarySheetCollaboration) {
        summarySheetCollaboration.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [receivedUrlHashParam]);

  const sortedDocuments = sortDocumentsPerLot(documents);

  return (
    <ScrollArea h="100%" sx={{ flexGrow: 1 }}>
      <Stack
        p="04"
        pt={0}
        spacing="04"
        w={`calc(100vw - ${SOURCE_SIDE_PANEL_WIDTH + MIN_MARGIN_LEFT_TO_ENABLE_USER_TO_CLICK_OUTSIDE}px)`}
        miw={420}
        maw={856}
      >
        <CriterionElement
          criterionAnswer={criterionAnswerDetailQuery.data}
          tenderId={tenderId}
        />
        {user && createdAt && (
          <EditedAtRow
            user={user}
            date={createdAt}
            thread={thread}
            criterionAnswerId={criterionAnswerId}
            tenderId={tenderId}
          />
        )}
        {sources && sources.length > 0 && hasAnswer && (
          <SourcesSection sources={sources} tenderId={tenderId} />
        )}
        <SummarySheetCollaboration
          thread={thread}
          summaryCriterionAnswerId={criterionAnswerId}
          tenderId={tenderId}
        />
        {!hasAnswer && <DocumentSections sortedDocuments={sortedDocuments} />}
      </Stack>
    </ScrollArea>
  );
}

type DocumentSectionsProps = {
  sortedDocuments: Record<string, Document[]>;
};

function DocumentSections({ sortedDocuments }: DocumentSectionsProps) {
  const { openPanel, panelContext } = useDocumentSidePanel({
    documents: Object.values(sortedDocuments).flat(),
  });

  const handleClick = (selectedDocument: Document) => {
    if (!panelContext) {
      openPanel(selectedDocument);
    }
    panelContext?.updateData({
      ...panelContext?.data,
      selectedDocument,
    });
  };

  return (
    <Stack>
      {Object.entries(sortedDocuments).map(([section, documents]) => {
        return (
          <DocumentsAccordion
            key={section}
            documents={documents}
            title={section}
            sortBy="fileExtension"
            openDocumentSidePanel={handleClick}
          />
        );
      })}
    </Stack>
  );
}
