import { useInfiniteQuery } from '@tanstack/react-query';

import { getRenewalTendersWithInteraction } from '../../../shared/api/magellan/interaction';
import { DecisionRenewalStatus } from '../../../shared/entities/Interaction';
import { usePipelineRenewalsFilters } from './usePipelineRenewalsFilters.hook';
import { RESULTS_PER_PAGE } from './useTendersWithInteractionQuery.hook';

export function useRenewalTendersWithInteractionQuery() {
  const { filters, isLoaded: isPipelineRenewalsFiltersLoaded } =
    usePipelineRenewalsFilters();

  const params = {
    ...(filters.owner && { ownerId: filters.owner.join(',') }),
    decisionRenewalStatus: DecisionRenewalStatus.TO_ANALYZE,
    take: RESULTS_PER_PAGE,
  };

  return useInfiniteQuery({
    enabled: isPipelineRenewalsFiltersLoaded,
    initialPageParam: 0,
    queryKey: [getRenewalTendersWithInteraction.name, params],
    queryFn: ({ pageParam }) =>
      getRenewalTendersWithInteraction({ ...params, skip: pageParam }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const { skip, totalResults, take } = lastPage.pagination;
      return skip + take < totalResults
        ? lastPageParam + RESULTS_PER_PAGE
        : null;
    },
  });
}
