import { useEffect, useState } from 'react';

import { useLocalStorage } from '@mantine/hooks';

import { TableColumn } from '../components/decisionTables/utils/columnDefinitions';

export type PipelineRenewalsFilters = Partial<
  Record<TableColumn.OWNER, string[]>
>;

export function usePipelineRenewalsFilters() {
  const [filters, setFilters] = useLocalStorage<PipelineRenewalsFilters>({
    key: 'pipeline-renewals-table-filters',
    defaultValue: {},
  });

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const resetFilters = () => {
    setFilters({});
  };

  const removeFilter = (columnId: TableColumn.OWNER) => {
    setFilters(prev => {
      const newFilters = { ...prev };
      delete newFilters[columnId];
      return newFilters;
    });
  };

  const setOwnerFilter = (value: string[]) => {
    if (value.length === 0) {
      removeFilter(TableColumn.OWNER);
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        [TableColumn.OWNER]: value,
      }));
    }
  };

  return {
    isLoaded,
    filters,
    setOwnerFilter,
    resetFilters,
    removeFilter,
  };
}
