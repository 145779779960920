import { Text } from '@mantine/core';
import type { MRT_Cell } from 'mantine-react-table';

import type { TenderWithListData } from '../../../../../../shared/api/magellan/interaction/dto';

type ReasonCellData = {
  reason: string;
};

type ReasonCellProps = {
  cell: MRT_Cell<TenderWithListData>;
};

export function ReasonCell({ cell }: ReasonCellProps) {
  const { reason } = cell.getValue<ReasonCellData>();
  return (
    <Text variant="sm" fw={400} c="gray.9" truncate>
      {reason}
    </Text>
  );
}
