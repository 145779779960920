import { Text, createStyles } from '@mantine/core';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Tooltip } from '../../../../shared/components/UI/Tooltip/Tooltip';

import * as KeyInfoTooltip from '../../../../shared/components/KeyInfoTooltip';
import IconEye from '../../../../shared/assets/ai_icons/eye.svg?react';
import type { DataPointSource } from '../../../../shared/entities/DataPointMeta';
import { useSourceSidePanel } from '../../../../shared/hooks/useSourceSidePanel';
import { useTenderQuery } from '../../../../shared/hooks/useTenderQuery.hook';
import { formatCurrency } from '../../../../shared/utils/curencies';
import { useNoticeContext } from '../../contexts/Notice.provider';

const useStyle = createStyles(theme => ({
  wrapper: {
    borderRadius: theme.radius.md,
    backgroundColor: 'white',
    border: `1px solid ${theme.colors.gray[1]}`,
    shadow: theme.shadows.xs,
    padding: `${theme.spacing['02']} ${theme.spacing['03']}`,
  },
  title: {
    fontWeight: 500,
    color: theme.colors.gray[6],
    whiteSpace: 'nowrap',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing['01'],
    justifyContent: 'flex-end',
    minWidth: '40px',
    fontWeight: 400,
    whiteSpace: 'nowrap',
    color: theme.colors.gray[9],
    '& > div': { flexGrow: 1 },
  },
  aiText: {
    color: 'transparent',
    background: theme.other.gradients.aiHorizontal,
    backgroundClip: 'text',
  },
  eyeIcon: {
    cursor: 'pointer',
  },
  dropdown: {
    color: 'white',
    background: theme.colors.violet[6],
    transform: `translateX(-${theme.spacing['03']})`,
    maxWidth: '280px',
  },
}));

export function Amount() {
  const { t } = useTranslation();

  const { id: tenderIdString } = useParams();
  const tenderId = Number(tenderIdString);
  const { data: tender } = useTenderQuery(tenderId);

  const sources = tender.estimatedValueInEurMeta?.dataPointSources || [];

  const formattedValue = formatCurrency(tender.estimatedValueInEur) ?? '-';

  return (
    <KeyInfoDisplay
      title={t('amount')}
      value={formattedValue}
      isLlmEnriched={!!tender.isEstimatedValueInEurLLMEnriched}
      sources={sources}
    />
  );
}

export function Duration() {
  const { t } = useTranslation();
  const { tender } = useNoticeContext();

  const sources = tender.durationInMonthMeta?.dataPointSources || [];

  const formattedValue = formatDurationValue(tender.durationInMonth) ?? '-';

  return (
    <KeyInfoDisplay
      title={t('duration')}
      value={formattedValue}
      isLlmEnriched={!!tender.isDurationInMonthLLMEnriched}
      sources={sources}
    />
  );
}

type KeyInfoDisplayProps = {
  title: string;
  value: string;
  isLlmEnriched: boolean;
  sources: DataPointSource[];
};

function KeyInfoDisplay({
  title,
  value,
  isLlmEnriched,
  sources,
}: KeyInfoDisplayProps) {
  const { classes, cx } = useStyle();

  const { tender } = useNoticeContext();
  const { openPanel, panelContext } = useSourceSidePanel({
    tenderId: tender.id,
    sources,
  });
  const showEyeIcon = isLlmEnriched && sources.length > 0;
  const handleClick = () => {
    if (!panelContext) {
      openPanel(sources[0]);
    }
    panelContext?.updateData({
      ...panelContext?.data,
      selectedSource: sources[0],
    });
  };
  return (
    <div className={classes.wrapper}>
      <Text variant="xs">{title}</Text>
      <Tooltip
        classNames={{ dropdown: classes.dropdown }}
        position="bottom-start"
        offset={16}
        isVisibile={isLlmEnriched}
        content={<KeyInfoTooltip.Duration />}
      >
        <div className={classes.value}>
          <Text variant="md" className={cx(isLlmEnriched && classes.aiText)}>
            {value}
          </Text>
          {showEyeIcon && (
            <IconEye className={classes.eyeIcon} onClick={handleClick} />
          )}
        </div>
      </Tooltip>
    </div>
  );
}

function formatDurationValue(durationInMonth: number | undefined | null) {
  if (!durationInMonth) {
    return null;
  }

  return `${durationInMonth} mois`;
}
