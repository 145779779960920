import { useMutation, useQueryClient } from '@tanstack/react-query';

import { displayDecisionNotification } from '../../../../../shared/components/notifications/decisionNotifications';
import type {
  DecisionRenewalStatus,
  DecisionStatus,
} from '../../../../../shared/entities/Interaction';
import type { RagGenerability } from '../../../../../shared/entities/Tender';
import { upsertDecision } from '../../../../../shared/utils/upsertDecision';
import { updatePageTenders } from '../utils/updatePageTenders';

export const useDecisionMutation = (
  tenderId: number,
  queryKey: string[],
  ragGenerability: RagGenerability,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      decision,
      reason,
      winningAmount,
    }: {
      decision: DecisionStatus;
      reason?: string;
      winningAmount?: number;
    }) =>
      upsertDecision({
        tenderId,
        status: { type: 'DecisionStatus', value: decision },
        reason,
        winningAmount,
      }),
    onSuccess: (_res, params) => {
      displayDecisionNotification(params.decision, ragGenerability);
      queryClient.setQueryData(queryKey, (prev: { pages: any[] }) => {
        const updatedPages = prev.pages.map(
          updatePageTenders({ ...params, tenderId }),
        );
        return { ...prev, pages: updatedPages };
      });
    },
  });
};

export const useRenewalDecisionMutation = (
  tenderId: number,
  queryKey: string[],
  ragGenerability: RagGenerability,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      renewalStatus,
      reason,
    }: {
      renewalStatus: DecisionRenewalStatus;
      reason?: string;
    }) =>
      upsertDecision({
        tenderId,
        status: { type: 'DecisionRenewalStatus', value: renewalStatus },
        reason,
      }),
    onSuccess: (_res, params) => {
      displayDecisionNotification(params.renewalStatus, ragGenerability);
      queryClient.setQueryData(queryKey, (prev: { pages: any[] }) => {
        const updatedPages = prev.pages.map(
          updatePageTenders({ ...params, tenderId }),
        );
        return { ...prev, pages: updatedPages };
      });
    },
  });
};
