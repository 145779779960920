import { Center, Image, Stack, Text, Title } from '@mantine/core';

import EmptyActivitiesIllustration from '../assets/empty_activities.png';

export function EmptyActivitiesPlaceholder() {
  return (
    <Center
      h="100%"
      mx="03"
      px="05"
      bg="linear-gradient(180deg, #F7FBFF 34%, rgba(247, 251, 255, 0) 100%)"
      sx={theme => ({ borderRadius: theme.radius.md, flexGrow: 1 })}
    >
      <Stack align="center" spacing="05">
        <Image
          src={EmptyActivitiesIllustration}
          alt="Illustration de fil d'activité vide"
          maw="116px"
          mah="116px"
        />
        <Stack spacing="02" align="center">
          <Title align="center" order={4} c="gray.9">
            Retrouvez toute l'activité liée à cette opportunité
          </Title>
          <Text variant="sm" fw={400} c="gray.5" align="center">
            Suivez les mises à jour de statut, décisions, et plus faites par
            votre équipe
          </Text>
        </Stack>
      </Stack>
    </Center>
  );
}
