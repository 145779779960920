import type { MRT_TableOptions } from 'mantine-react-table';

import type { TenderWithListData } from '../../../../../shared/api/magellan/interaction/dto';
import { DecisionRenewalStatus } from '../../../../../shared/entities/Interaction';
import type { DecisionStatusTab } from '../../../hooks/useTabs.hook';
import { EXIT_ANIMATION_DURATION } from '../../../hooks/useTendersWithInteractionQuery.hook';
import { EmptyDecisionList } from '../../EmptyPipeline';
import { EmptyDecisionRenewalList } from '../../EmptyRenewalPipeline';

type TableConfig = Partial<MRT_TableOptions<TenderWithListData>>;

function makeTableBaseConfig(): TableConfig {
  return {
    memoMode: 'cells',
    enableSorting: false,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enablePagination: false,
    enableTableFooter: true,
    mantinePaperProps: {
      sx: theme => ({
        overflowY: `auto`,
        border: 'none !important',
        '& thead': {
          position: 'sticky',
          top: `0px`,
          zIndex: 10,
        },
        '& thead > tr > th': {
          border: 'none !important',
          paddingLeft: theme.spacing['03'],
          paddingRight: theme.spacing['03'],
          paddingTop: 0,
          paddingBottom: 0,
          height: theme.spacing['06'],
          justifyContent: 'center',
        },
        '& thead > tr > th > div ': {
          color: theme.colors.gray[6],
          fontWeight: 500,
          fontSize: theme.fontSizes.xs,
        },
        '& tbody tr': {
          textDecoration: 'none',
          color: 'inherit',
          ':hover': {
            cursor: 'pointer',
          },
          ':hover > td': {
            backgroundColor: theme.colors.gray[0],
          },
          borderTop: `1px solid ${theme.colors.gray[1]}`,
        },
        '& tbody > tr > td': {
          padding: theme.spacing['03'],
          borderTop: 'none !important',
          borderBottom: 'none !important',
        },
      }),
    },
    mantineTableHeadRowProps: {
      sx: theme => ({
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.colors.gray[1]}`,
        position: 'sticky',
      }),
    },
    mantineTableContainerProps: {
      sx: theme => ({
        paddingRight: theme.spacing['05'],
        paddingLeft: theme.spacing['05'],
        paddingBottom: theme.spacing['05'],
      }),
    },
    layoutMode: 'grid',
    initialState: { density: 'xs' },
    state: { columnOrder: [] },
  };
}

export function makeDecisionTableConfig({
  decisionStatus,
  onRowClick,
}: {
  decisionStatus: DecisionStatusTab;
  onRowClick: (
    e: React.MouseEvent<HTMLTableRowElement>,
    tenderId: number,
  ) => void;
}): TableConfig {
  return {
    ...makeTableBaseConfig(),
    renderEmptyRowsFallback: () => (
      <EmptyDecisionList decisionStatus={decisionStatus} />
    ),
    mantineTableBodyRowProps: ({ row: { original: tender } }) => ({
      onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
        onRowClick(event, tender.id);
      },
      sx: () => ({
        transition: tender.transform && `all ${EXIT_ANIMATION_DURATION}ms ease`,
        transform: tender.transform || 'none',
      }),
    }),
  };
}

export function makeRenewalDecisionTableConfig({
  onRowClick,
}: {
  onRowClick: (
    event: React.MouseEvent<HTMLTableRowElement>,
    tenderId: number,
  ) => void;
}): TableConfig {
  return {
    ...makeTableBaseConfig(),
    renderEmptyRowsFallback: () => (
      <EmptyDecisionRenewalList
        decisionRenewalStatus={DecisionRenewalStatus.TO_ANALYZE}
      />
    ),
    mantineTableBodyRowProps: ({ row: { original: tender } }) => ({
      onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
        onRowClick(event, tender.id);
      },
    }),
  };
}
