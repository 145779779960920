import React from 'react';

import type { MantineTheme } from '@mantine/core';
import { Group } from '@mantine/core';
import { modals } from '@mantine/modals';

import {
  IconArrowRight,
  IconCheck,
  IconExternalLink,
  IconTrophy,
  IconTrophyOff,
  IconX,
} from '@tabler/icons-react';

import { Button } from '../../../../../shared/components/UI/Button/Button';

import type { TengoModals } from '../../../../../shared/contexts/Modals.provider';
import { DecisionStatus } from '../../../../../shared/entities/Interaction';
import { DecisionStep } from './DecisionWidget.types';

type ButtonConfig = {
  status?: DecisionStatus;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  color: 'primary' | 'red';
  text: string;
  modalStringKey?: TengoModals; //will be opened with the modal provider.
  inactiveVariant: 'filled' | 'subtle' | 'light';
  activeVariant: 'filled' | 'subtle' | 'light';
  nextStep?: DecisionStep;
};

const buttonConfigs: { [key in DecisionStep]: ButtonConfig[] } = {
  [DecisionStep.EVALUATION]: [
    {
      status: DecisionStatus.REJECTED,
      iconLeft: <IconX />,
      color: 'red',
      text: 'Rejeter',
      activeVariant: 'filled',
      inactiveVariant: 'light',
    },
    {
      status: DecisionStatus.TO_ANALYZE,
      iconLeft: <IconCheck />,
      color: 'primary',
      text: 'À analyser',
      activeVariant: 'filled',
      inactiveVariant: 'light',
      nextStep: DecisionStep.GONOGO,
    },
  ],
  [DecisionStep.GONOGO]: [
    {
      status: DecisionStatus.NOGO,
      iconLeft: <IconX />,
      color: 'red',
      text: 'No go',
      modalStringKey: 'nogoReason',
      activeVariant: 'filled',
      inactiveVariant: 'light',
    },
    {
      status: DecisionStatus.GO,
      iconLeft: <IconCheck />,
      color: 'primary',
      text: 'Go',
      activeVariant: 'filled',
      inactiveVariant: 'light',
      nextStep: DecisionStep.SUBMISSION,
    },
  ],
  [DecisionStep.SUBMISSION]: [
    {
      iconRight: <IconExternalLink />,
      color: 'primary',
      text: 'Répondre sur la plateforme',
      activeVariant: 'filled',
      inactiveVariant: 'subtle',
    },
    {
      status: DecisionStatus.ANSWERED,
      iconLeft: <IconArrowRight />,
      color: 'primary',
      text: 'Offre déposée',
      activeVariant: 'filled',
      inactiveVariant: 'light',
      nextStep: DecisionStep.RESULTS,
    },
  ],
  [DecisionStep.RESULTS]: [
    {
      status: DecisionStatus.LOSS,
      iconLeft: <IconTrophyOff />,
      color: 'red',
      text: 'Perdu',
      modalStringKey: 'lossReason',
      activeVariant: 'filled',
      inactiveVariant: 'light',
    },
    {
      status: DecisionStatus.WIN,
      iconLeft: <IconTrophy />,
      color: 'primary',
      text: 'Gagné',
      modalStringKey: 'winReason',
      activeVariant: 'filled',
      inactiveVariant: 'light',
    },
  ],
};

type DecisionButtonsGroupProps = {
  currentStep: DecisionStep;
  onDecision: (decision: DecisionStatus) => void;
  decisionStatus: DecisionStatus;
  participationURL?: string;
  isCollapsedHeader?: boolean;
};

export const DecisionButtonsGroup = ({
  currentStep,
  decisionStatus,
  onDecision,
  participationURL,
  isCollapsedHeader = false,
}: DecisionButtonsGroupProps) => {
  const currentButtons = buttonConfigs[currentStep] || [];

  return (
    <Group noWrap spacing="03">
      {currentButtons.map((config, index) => (
        <DecisionButton
          key={index}
          config={config}
          onDecision={onDecision}
          decisionStatus={decisionStatus}
          participationURL={participationURL}
          isCollapsedHeader={isCollapsedHeader}
        />
      ))}
    </Group>
  );
};

type DecisionButtonProps = {
  config: (typeof buttonConfigs)[keyof typeof buttonConfigs][0];
  onDecision: (decision: DecisionStatus) => void;
  decisionStatus: DecisionStatus;
  participationURL?: string;
  isCollapsedHeader?: boolean;
};

const DecisionButton = ({
  config,
  onDecision,
  decisionStatus,
  participationURL,
  isCollapsedHeader = false,
}: DecisionButtonProps) => {
  const isDisabled = decisionStatus === config.status && !config.nextStep;

  const buttonVariant =
    decisionStatus === config.status
      ? config.activeVariant
      : config.inactiveVariant;

  const handleClick = () => {
    if (config.modalStringKey && !isDisabled) {
      modals.openContextModal({
        modal: config.modalStringKey,
        innerProps: { onDecision, nextStatus: config.status },
      });
    } else if (config.status && !isDisabled) {
      onDecision(config.status);
    } else if (participationURL) {
      window.open(participationURL, '_blank');
    } else {
      throw new Error(
        `No action defined config:${config.status},  status: ${decisionStatus}`,
      );
    }
  };

  const isParticipationButton = !config.status && !config.nextStep;
  if (isParticipationButton && !participationURL) {
    return null;
  }

  return (
    <Button
      color={config.color}
      variant={buttonVariant}
      size={isCollapsedHeader ? 'sm' : 'md'}
      leftIcon={config.iconLeft}
      rightIcon={config.iconRight}
      onClick={handleClick}
      disabled={isDisabled}
      sx={(theme: MantineTheme) => ({
        ':disabled': {
          background: `linear-gradient(83.76deg, ${theme.colors[config.color][6]} 0%, ${
            theme.colors[config.color][7]
          } 100%)`,
          color: 'white',
        },
      })}
    >
      {config.text}
    </Button>
  );
};
