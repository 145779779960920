import { Text } from '@mantine/core';

import {
  IconCalendarDue,
  IconCircleDashed,
  IconUserCircle,
} from '@tabler/icons-react';

import { DatePicker } from '../../../../../shared/components/UI/DatePicker/DatePicker';
import { MultiCheckbox } from '../../../../../shared/components/UI/MultiCheckbox/MultiCheckbox';
import TenderStatusBadge from '../../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';
import { UserWithAvatar } from '../../../../../shared/components/UI/UserWithAvatar/UserWithAvatar';

import * as dateUtils from '../../../../../shared/utils/dates';
import { DecisionStatus } from '../../../../../shared/entities/Interaction';
import { TenderStatus } from '../../../../../shared/entities/Tender';
import type { User } from '../../../../../shared/entities/User';
import { useActiveUserQuery } from '../../../../../shared/hooks/useActiveUserQuery.hook';
import { useUsersQuery } from '../../../../../shared/hooks/useUsersQuery.hook';
import type { PipelineFilters } from '../../../hooks/usePipelineFilters.hook';
import type { PipelineRenewalsFilters } from '../../../hooks/usePipelineRenewalsFilters.hook';
import { useTabsStatus } from '../../../hooks/useTabs.hook';
import { TableColumn } from '../utils/columnDefinitions';
import { dateFilterToValue } from '../utils/utils';

export function Status({
  filters,
  onChange,
}: {
  filters: PipelineFilters;
  onChange: (newVals: string[]) => void;
}) {
  return (
    <MultiCheckbox
      label="Status"
      icon={<IconCircleDashed size={16} />}
      values={filterValuesToOptions(filters[TableColumn.STATUS])}
      options={[
        {
          value: TenderStatus.OPEN,
          label: <TenderStatusBadge status={TenderStatus.OPEN} />,
        },
        {
          value: TenderStatus.CLOSED,
          label: <TenderStatusBadge status={TenderStatus.CLOSED} />,
        },
      ]}
      onChange={newVals => onChange(newVals.map(({ value }) => value))}
    />
  );
}

export function ResponseDeadline({
  filters,
  onChange,
}: {
  filters: PipelineFilters;
  onChange: (newVals: string[]) => void;
}) {
  const { activeTabStatus } = useTabsStatus();

  const isShowing = [DecisionStatus.TO_ANALYZE, DecisionStatus.GO].includes(
    activeTabStatus,
  );

  if (!isShowing) {
    return null;
  }

  return (
    <DatePicker
      label="À déposer avant le"
      icon={<IconCalendarDue size={16} />}
      value={dateFilterToValue(filters[TableColumn.RESPONSE_DEADLINE])}
      onChange={newVal =>
        onChange(
          newVal ? [dateUtils.parseDateToFrLocaleDateString(newVal)] : [],
        )
      }
    />
  );
}

export function Owner({
  filters,
  onChange,
}: {
  filters: PipelineFilters | PipelineRenewalsFilters;
  onChange: (newVals: string[]) => void;
}) {
  const { data: users } = useUsersQuery();
  const {
    data: { user: activeUser },
  } = useActiveUserQuery();

  const orderedUsers = orderUsersWithActiveUserFirst(users, activeUser);

  const options = orderedUsers.map(user => ({
    value: user.id,
    label: (
      <UserWithAvatar
        avatarColor={user.avatarColor}
        firstName={user.firstName}
        lastName={user.lastName}
        isDisabled={!!user.disabledAt}
      />
    ),
  }));

  return (
    <MultiCheckbox
      label="Responsable"
      icon={<IconUserCircle size={16} />}
      h={256}
      values={filterValuesToOptions(filters[TableColumn.OWNER])}
      options={options}
      popoverProps={{ middlewares: { flip: false, shift: false } }}
      onChange={newVals => onChange(newVals.map(({ value }) => value))}
    />
  );
}

export function ResetButton({
  filters,
  resetFilters,
}: {
  filters: PipelineFilters | PipelineRenewalsFilters;
  resetFilters: () => void;
}) {
  if (isEmpty(filters)) return null;

  return (
    <Text
      variant="sm"
      fw={500}
      c="gray.6"
      onClick={resetFilters}
      sx={{ ':hover': { cursor: 'pointer' } }}
    >
      Réinitialiser
    </Text>
  );
}

function filterValuesToOptions(value?: string[]) {
  return value ? value.map(v => ({ value: v, label: v })) : [];
}

function orderUsersWithActiveUserFirst(users: User[], activeUser: User) {
  return users?.sort((a, b) => {
    if (a.email === activeUser.email) return -1;
    if (b.email === activeUser.email) return 1;
    return 0;
  });
}

function isEmpty(obj: object): boolean {
  return Object.keys(obj).length === 0;
}
