import { useEffect } from 'react';

import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';

import { useTranslation } from 'react-i18next';

import * as columnDefs from '../utils/columnDefinitions';
import * as SkeletonLoader from './SkeletonLoader';
import { DecisionStatus } from '../../../../../shared/entities/Interaction';
import { useNavigateWithControl } from '../../../../../shared/hooks/useNavigateWithControl.hook';
import { useTabsStatus } from '../../../hooks/useTabs.hook';
import { useTendersWithInteractionQuery } from '../../../hooks/useTendersWithInteractionQuery.hook';
import type { I18nTFunction } from '../utils/columnDefinitions';
import { makeDecisionTableConfig } from '../utils/tableConfig';
import { InfiniteScroll } from './InfiniteScroll';

function getDecisionTableColumns(
  t: I18nTFunction,
  decisionStatus: DecisionStatus,
) {
  switch (decisionStatus) {
    case DecisionStatus.TO_ANALYZE:
    case DecisionStatus.GO:
      return [
        columnDefs.summarySheetRecap(t),
        columnDefs.opportunity(t),
        columnDefs.status(t),
        columnDefs.responseDeadline(t),
        columnDefs.owner(t),
        columnDefs.contributions(t),
        columnDefs.actions(),
      ];
    case DecisionStatus.NOGO:
      return [
        columnDefs.summarySheetRecap(t),
        columnDefs.opportunity(t),
        columnDefs.status(t),
        columnDefs.owner(t),
        columnDefs.nogo(t),
        columnDefs.contributions(t),
      ];
    case DecisionStatus.ANSWERED:
      return [
        columnDefs.opportunity(t),
        columnDefs.status(t),
        columnDefs.answeredAt(t),
        columnDefs.owner(t),
        columnDefs.contributions(t),
        columnDefs.actions(),
      ];
    case DecisionStatus.WIN:
      return [
        columnDefs.opportunity(t),
        columnDefs.status(t),
        columnDefs.owner(t),
        columnDefs.contributions(t),
        columnDefs.winAmount(t),
        columnDefs.winReason(t),
      ];
    case DecisionStatus.LOSS:
      return [
        columnDefs.opportunity(t),
        columnDefs.status(t),
        columnDefs.owner(t),
        columnDefs.lossReason(t),
        columnDefs.contributions(t),
      ];
    default:
      throw new Error(`Unknown status: ${decisionStatus}`);
  }
}

export function DecisionTables() {
  const { activeTabStatus: decisionStatus } = useTabsStatus();
  const { t } = useTranslation('pipeline');

  const tendersWithInteractionQuery = useTendersWithInteractionQuery();
  const tableData = tendersWithInteractionQuery.data?.pages.flatMap(
    pageData => pageData.tenders,
  );

  const navigateWithControl = useNavigateWithControl();
  const tableConfig = makeDecisionTableConfig({
    decisionStatus,
    onRowClick: (event, tenderId) =>
      navigateWithControl(event, `/notices/${tenderId}`),
  });

  const table = useMantineReactTable({
    columns: getDecisionTableColumns(t, decisionStatus),
    data: tableData ?? [],
    ...tableConfig,
  });

  useEffect(() => {
    // this useEffect triggers a re-render when the data is updated so we can run the row transition animation
    void tendersWithInteractionQuery.dataUpdatedAt;
  }, [tendersWithInteractionQuery.dataUpdatedAt]);

  return tableData ? (
    <>
      <MantineReactTable table={table} />
      <InfiniteScroll
        isFetching={tendersWithInteractionQuery.isFetching}
        fetchNextPage={tendersWithInteractionQuery.fetchNextPage}
        hasNextPage={tendersWithInteractionQuery.hasNextPage}
      />
    </>
  ) : (
    <SkeletonLoader.Table />
  );
}
