import { Avatar } from '../../../../../shared/components/UI/Avatar/Avatar';

import type { RichTextJson } from '../../../../../shared/entities/Comment';
import { useActiveUserQuery } from '../../../../../shared/hooks/useActiveUserQuery.hook';
import { useCreateCommentQuery } from '../hooks/useCreateCommentQuery.hook';
import { CommentInput } from './CommentInput';

type NewCommentInputProps = {
  threadId: number;
  showBorderOnFocus?: boolean;
  tenderId: number;
};

export function NewCommentInput({
  threadId,
  showBorderOnFocus,
  tenderId,
}: NewCommentInputProps) {
  const activeUserQuery = useActiveUserQuery();
  const { createComment } = useCreateCommentQuery(tenderId);

  const { user: activeUser } = activeUserQuery.data;

  return (
    <CommentInput
      avatar={
        <Avatar color={activeUser.avatarColor}>
          {activeUser.firstName[0].toUpperCase() +
            activeUser.lastName[0].toUpperCase()}
        </Avatar>
      }
      placeholder="répondre..."
      saveComment={(content: RichTextJson) =>
        createComment({ content, threadId })
      }
      variant="subtle"
      showBorderOnFocus={showBorderOnFocus}
    />
  );
}
