import { useTranslation } from 'react-i18next';

import { useSidepanel } from '../../../../../shared/contexts/Sidepanel.provider';
import type { Document } from '../../../../../shared/entities/Document';
import { AnswerDetailPanel } from '../components/answerDetailPanel/AnswerDetailPanel';

export const useOpenDetailPanel = (tenderId: number, documents: Document[]) => {
  const { togglePanel, closePanel } = useSidepanel();
  const { t } = useTranslation(['summarySheet']);

  const openSidepanel = (criterionAnswerId: number) => {
    togglePanel({
      header: t('title'),
      content: (
        <AnswerDetailPanel
          tenderId={tenderId}
          criterionAnswerId={criterionAnswerId}
          documents={documents}
          closePanel={closePanel}
        />
      ),
    });
  };

  return { openSidepanel };
};
