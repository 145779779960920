import type { Document } from '../../../../../shared/entities/Document';
import { naturalCompare } from '../../../../../shared/utils/strings';

const DEPRIORITIZED_EXTENSIONS = ['.pdf', '.docx'] as const;

export const sortDocumentsPerFileExtension = (
  documents: Document[],
): Document[] => {
  const sortedDocuments = [...documents];

  return sortedDocuments.sort((a, b) => {
    const isADeprioritized = hasDeprioritizedExtension(a.originalName);
    const isBDeprioritized = hasDeprioritizedExtension(b.originalName);

    if (isADeprioritized === isBDeprioritized) {
      return naturalCompare(a.prettyName, b.prettyName);
    }

    return isADeprioritized ? 1 : -1;
  });
};

const hasDeprioritizedExtension = (fileName: string): boolean => {
  return DEPRIORITIZED_EXTENSIONS.some(ext =>
    fileName.toLowerCase().endsWith(ext),
  );
};
