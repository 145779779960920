import { useEffect } from 'react';
import { useState } from 'react';

import { useLocalStorage } from '@mantine/hooks';

import { TableColumn } from '../components/decisionTables/utils/columnDefinitions';

type TableColumnWithFilter =
  | TableColumn.STATUS
  | TableColumn.OWNER
  | TableColumn.RESPONSE_DEADLINE;

export type PipelineFilters = Partial<Record<TableColumnWithFilter, string[]>>;

export function usePipelineFilters() {
  const [filters, setFilters] = useLocalStorage<PipelineFilters>({
    key: 'pipeline-table-filters',
    defaultValue: {},
  });

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const resetFilters = () => {
    setFilters({});
  };

  const removeFilter = (columnId: TableColumnWithFilter) => {
    setFilters(prev => {
      const newFilters = { ...prev };
      delete newFilters[columnId];
      return newFilters;
    });
  };

  const setStatusFilter = (value: string[]) => {
    if (value.length === 0) {
      removeFilter(TableColumn.STATUS);
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        [TableColumn.STATUS]: value,
      }));
    }
  };

  const setOwnerFilter = (value: string[]) => {
    if (value.length === 0) {
      removeFilter(TableColumn.OWNER);
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        [TableColumn.OWNER]: value,
      }));
    }
  };

  const setResponseDeadlineFilter = (value: string[]) => {
    if (value.length === 0) {
      removeFilter(TableColumn.RESPONSE_DEADLINE);
    } else {
      setFilters(prevFilters => ({
        ...prevFilters,
        [TableColumn.RESPONSE_DEADLINE]: value,
      }));
    }
  };

  return {
    isLoaded,
    filters,
    setStatusFilter,
    setOwnerFilter,
    setResponseDeadlineFilter,
    resetFilters,
    removeFilter,
  };
}
