import type { MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';

export const useNavigateWithControl = () => {
  const navigate = useNavigate();
  const navigateWithControl = (event: MouseEvent<any>, to: string) => {
    // ctrl/cmd + click or middle mouse button
    if (event.ctrlKey || event.metaKey || event.button === 1) {
      const newWindow = window.open(to, '_blank', 'noopener,noreferrer');
      if (newWindow) {
        newWindow.opener = null;
      }
    } else if (event.button === 0) {
      navigate(to);
    }
  };

  return navigateWithControl;
};
