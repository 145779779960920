import { Group, Image, Text } from '@mantine/core';
import type { MRT_Cell } from 'mantine-react-table';

import AIIconSparkle from '../../../../../../shared/assets/ai_icons/sparkle.svg';

export type DurationCellData = {
  duration: number;
  isLlmEnriched: boolean;
};

type DurationCellProps = {
  cell: MRT_Cell<any>;
};

export function DurationCell({ cell }: DurationCellProps) {
  const { duration, isLlmEnriched } = cell.getValue<DurationCellData>();

  if (isLlmEnriched) {
    return <LlmDuration duration={duration} />;
  }
  return <Text>{duration ? `${duration} mois` : '-'}</Text>;
}

const LlmDuration = ({ duration }: { duration: number }) => {
  return (
    <Group noWrap spacing="01">
      <Text
        variant="sm"
        fw={500}
        sx={theme => ({
          background: theme.other.gradients.aiHorizontal,
          backgroundClip: 'text',
          color: 'transparent',
        })}
      >
        {`${duration} mois`}
      </Text>
      <Image src={AIIconSparkle} alt="llm" maw={16} />
    </Group>
  );
};
