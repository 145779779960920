import type { RichTextJson } from '../../../entities/Comment';
import { magellanClient } from '../../../infra/axios';
import type { DeleteCommentResponseDTO } from './dto';

type CreateThreadParams = {
  tenderId: number;
  content: RichTextJson;
  summaryCriterionAnswerId?: number;
};

export async function createThread({
  tenderId,
  content,
  summaryCriterionAnswerId,
}: CreateThreadParams): Promise<Comment> {
  const response = await magellanClient.post<Comment>(`/comments`, {
    tenderId,
    content,
    summaryCriterionAnswerId,
  });

  return response.data;
}

type CreateCommentParams = {
  tenderId: number;
  threadId: number;
  content: RichTextJson;
  summaryCriterionAnswerId?: number;
};

export async function createComment({
  tenderId,
  threadId,
  content,
  summaryCriterionAnswerId,
}: CreateCommentParams): Promise<Comment> {
  const response = await magellanClient.post<Comment>(`/comments`, {
    tenderId,
    threadId,
    content,
    summaryCriterionAnswerId,
  });

  return response.data;
}

export async function deleteComment(
  commentId: number,
): Promise<DeleteCommentResponseDTO> {
  const response = await magellanClient.delete<DeleteCommentResponseDTO>(
    `/comments/${commentId}`,
  );

  return response.data;
}

export async function updateComment(
  commentId: number,
  content: RichTextJson,
): Promise<Comment> {
  const response = await magellanClient.put<Comment>(`/comments/${commentId}`, {
    content,
  });

  return response.data;
}
