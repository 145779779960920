import { useMantineTheme } from '@mantine/core';
import { ModalsProvider as MantineModalsProvider } from '@mantine/modals';

import { OnboardingModal } from '../../features/onboarding/components/OnboardingModal';
import { CreateStreamSectionModal } from '../components/modals/CreateStreamSectionModal';
import { DeleteModal } from '../components/modals/DeleteConfirmationModal';
import { LossReasonModal } from '../components/modals/LossReasonModal';
import { NoGoReasonModal } from '../components/modals/NoGoReasonModal';
import { StreamDiscardModal } from '../components/modals/StreamDiscardModal';
import { WinReasonModal } from '../components/modals/WinReasonModal';
import { SaveCriteriaModal } from '../components/modals/summarySheet/saveCriteriaModal';

declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof tengoModals;
  }
}

export type TengoModals = keyof typeof tengoModals;

const tengoModals = {
  nogoReason: NoGoReasonModal,
  winReason: WinReasonModal,
  lossReason: LossReasonModal,
  streamDelete: DeleteModal,
  streamDiscard: StreamDiscardModal,
  commentDelete: DeleteModal,
  onboarding: OnboardingModal,
  createStreamSection: CreateStreamSectionModal,
  saveCriteria: SaveCriteriaModal,
  /* add your modal here */
};

export default function ModalsProvider({ children }: React.PropsWithChildren) {
  const theme = useMantineTheme();

  return (
    <MantineModalsProvider
      modals={tengoModals}
      modalProps={{
        yOffset: '20vh',
        radius: 'md',
        withCloseButton: false,
        overlayProps: {
          color: theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        },
      }}
    >
      {children}
    </MantineModalsProvider>
  );
}
