import { useEffect, useState } from 'react';

import { Box, Group, Skeleton, Stack, Text } from '@mantine/core';

import AIIconSparkle from '../../../../shared/assets/ai_icons/sparkle.svg?react';

export function AnalysisLoader() {
  return (
    <Stack w="fit-content" spacing="02">
      <Loader />
      <TextCaroussel />
    </Stack>
  );
}

const Loader = () => {
  return (
    <Box
      w="fit-content"
      p="2px"
      sx={theme => ({
        borderRadius: theme.radius.xl,
        background: `linear-gradient(93.26deg, rgba(255, 255, 255, 0.4) -10.36%, rgba(112, 114, 135, 0.4) 142.1%)`,
      })}
    >
      <Group
        noWrap
        w="fit-content"
        px="04"
        py="03"
        spacing="03"
        sx={theme => ({
          borderRadius: '22px',
          borderImageSlice: 1,
          boxShadow: theme.shadows.xs,
          background: 'white',
        })}
      >
        <AIIconSparkle style={{ width: '30px' }} />
        <Skeleton w="100px" h="6px" />
      </Group>
    </Box>
  );
};

const TextCaroussel = () => {
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (index === 4) {
      return;
    }
    const interval = setInterval(() => {
      setIndex(prev => (prev + 1) % 5);
    }, 2000);
    return () => clearInterval(interval);
  }, [index]);

  const texts = [
    "Ingestion de votre appel d'offres...",
    'Classement des documents...',
    'Lecture des documents...',
    "Analyse des enjeux de l'acheteur...",
    "Génération des attentes de l'acheteur...",
  ];

  return (
    <Text variant="xs" fw="400" c="gray.5">
      {texts[index]}
    </Text>
  );
};
