import { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';

export function useGlobalSearchFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setGlobalSearchFilter = useCallback(
    (value: string) => {
      const newSearchParams = new URLSearchParams(searchParams);

      if (value) {
        newSearchParams.set('q', value);
      } else {
        newSearchParams.delete('q');
      }
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  return {
    globalSearchFilter: searchParams.get('q') || '',
    setGlobalSearchFilter,
  };
}
