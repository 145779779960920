import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
  createComment,
  createThread,
} from '../../../../../shared/api/magellan/comments';
import type { RichTextJson } from '../../../../../shared/entities/Comment';
import { queryClient } from '../../../../../shared/infra/queryClient';
import { handleError } from '../utils/handleCommentError';

type CreateThreadType = {
  content: RichTextJson;
  threadId?: number;
  summaryCriterionAnswerId?: number;
};

export function useCreateCommentQuery(tenderId: number) {
  const { t } = useTranslation('collaboration');
  const refetch = () => queryClient.invalidateQueries({ queryKey: [] });

  const threadMutation = useMutation({
    mutationFn: ({
      content,
      threadId,
      summaryCriterionAnswerId,
    }: CreateThreadType) => {
      if (threadId) {
        return createComment({
          tenderId,
          threadId,
          content,
          summaryCriterionAnswerId,
        });
      }
      return createThread({ tenderId, content, summaryCriterionAnswerId });
    },
    onSuccess: () => {
      refetch();
    },
    onError: error => {
      handleError(error, t('errorMessages.createComment'));
    },
  });

  return {
    createThread: (props: Omit<CreateThreadType, 'threadId'>) =>
      threadMutation.mutate(props),
    createComment: (props: CreateThreadType) => threadMutation.mutate(props),
  };
}
