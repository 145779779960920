import { Group, Image, Stack } from '@mantine/core';

import {
  IconArrowRight,
  IconEye,
  IconSend,
  IconTrophy,
  IconTrophyOff,
  IconX,
} from '@tabler/icons-react';

import { DecisionStatus } from '../../../shared/entities/Interaction';
import DualPathSVG from '../assets/dual_path.svg';
import SinglePathSVG from '../assets/single_path.svg';
import { PipelineStep } from './PipelineStep';

export function Header() {
  return (
    <Stack
      p="05"
      spacing="05"
      sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[1]}` })}
    >
      <Group noWrap spacing={0} align="flex-start">
        <PipelineStep
          label="À analyser"
          icon={<IconEye size="14" />}
          decisionStatus={DecisionStatus.TO_ANALYZE}
        />
        <DoubleSeparator />
        <Stack maw="300px" sx={{ flexGrow: 1 }}>
          <PipelineStep
            label="Go"
            icon={<IconArrowRight size="14" />}
            decisionStatus={DecisionStatus.GO}
          />
          <PipelineStep
            label="No go"
            icon={<IconX size="14" />}
            decisionStatus={DecisionStatus.NOGO}
          />
        </Stack>
        <SingleSeparator />
        <PipelineStep
          label="Déposées"
          icon={<IconSend size="14" />}
          decisionStatus={DecisionStatus.ANSWERED}
        />
        <DoubleSeparator />
        <Stack maw="300px" sx={{ flexGrow: 1 }}>
          <PipelineStep
            label="Gagnés"
            icon={<IconTrophy size="14" />}
            decisionStatus={DecisionStatus.WIN}
          />
          <PipelineStep
            label="Perdus"
            icon={<IconTrophyOff size="14" />}
            decisionStatus={DecisionStatus.LOSS}
          />
        </Stack>
      </Group>
    </Stack>
  );
}

function SingleSeparator() {
  return <Image src={SinglePathSVG} alt="SinglePathSVG" maw={32} miw={32} />;
}

function DoubleSeparator() {
  return (
    <Image src={DualPathSVG} alt="DualPathSVG" maw={64} miw={64} mt="03" />
  );
}
