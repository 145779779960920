import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import {
  getDecisionCounts,
  getTendersWithInteraction,
} from '../../../shared/api/magellan/interaction';
import type { GetTendersWithInteractionResponseDTO } from '../../../shared/api/magellan/interaction/dto';
import { displayDecisionNotification } from '../../../shared/components/notifications/decisionNotifications';
import { DecisionStatus } from '../../../shared/entities/Interaction';
import type { TenderStatus } from '../../../shared/entities/Tender';
import { upsertDecision } from '../../../shared/utils/upsertDecision';
import { useGlobalSearchFilter } from './useGlobalSearchFilter.hook';
import { usePipelineFilters } from './usePipelineFilters.hook';
import { useTabsStatus } from './useTabs.hook';

export const RESULTS_PER_PAGE = 30;
export const EXIT_ANIMATION_DURATION = 300;

export function useTendersWithInteractionQuery() {
  const queryClient = useQueryClient();
  const { filters, isLoaded: isFiltersLoaded } = usePipelineFilters();
  const { globalSearchFilter } = useGlobalSearchFilter();
  const { activeTabStatus } = useTabsStatus();

  const params = {
    decisionStatus: activeTabStatus,
    ...(filters.owner && { ownerId: filters.owner.join(',') }),
    ...(filters.status && {
      tenderStatus: filters.status.join(',') as TenderStatus,
    }),
    ...(filters.responseDeadline && {
      responseDeadline: new Date(filters.responseDeadline[0]),
    }),
    ...(globalSearchFilter && { q: globalSearchFilter }),
    take: RESULTS_PER_PAGE,
  };

  const queryKey = [getTendersWithInteraction.name, params];

  const query = useInfiniteQuery({
    enabled: isFiltersLoaded,
    initialPageParam: 0,
    queryKey,
    queryFn: ({ pageParam }) =>
      getTendersWithInteraction({ ...params, skip: pageParam }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const { skip, totalResults, take } = lastPage.pagination;
      return skip + take < totalResults
        ? lastPageParam + RESULTS_PER_PAGE
        : null;
    },
  });

  const onTenderDecision = async (
    tenderId: number,
    status: DecisionStatus,
    reason?: string,
    winningAmount?: number,
  ) => {
    await upsertDecision({
      tenderId,
      status: { type: 'DecisionStatus', value: status },
      reason,
      winningAmount,
    });
    queryClient.invalidateQueries({ queryKey: [getDecisionCounts.name] });
    triggerExitTransition(tenderId, status);
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey });
    }, EXIT_ANIMATION_DURATION);
    displayDecisionNotification(status);
  };

  const triggerExitTransition = (tenderId: number, status: DecisionStatus) => {
    const currentData = queryClient.getQueryData<{
      pages: GetTendersWithInteractionResponseDTO[];
    }>(queryKey);

    if (!currentData) {
      return;
    }

    const tenders = [...currentData.pages.flatMap(page => page.tenders)];
    const index = tenders.findIndex(tender => tender.id === tenderId);

    const rejectionStatuses = [DecisionStatus.NOGO, DecisionStatus.LOSS];
    tenders[index].transform = rejectionStatuses.includes(status)
      ? 'translateX(-100%)'
      : 'translateX(100%)';
  };

  const onNoteChange = () => {
    query.refetch();
  };

  return { ...query, onTenderDecision, onNoteChange };
}
