import type { Comment } from './Comment';
import type { SummaryCriterionAnswer } from './SummaryCriterionAnswer';

export class Thread {
  id: number;
  interactionTenderId?: number;
  interactionCompanyId?: number;
  summaryCriterionAnswer?: SummaryCriterionAnswer;
  comments: Comment[];
}

export enum ThreadElementType {
  INTERACTION = 'interaction',
}
