import type { MantineColor } from '@mantine/core';
import { HoverCard, Text, createStyles } from '@mantine/core';

import {
  IconCheck,
  IconChecklist,
  IconExclamationMark,
  IconFileReport,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { useUrlHash } from '../../../../../../shared/contexts/UrlHash.provider';
import type { RAGStatus } from '../../../../../../shared/entities/RAGStatus';
import type { RagGenerability } from '../../../../../../shared/entities/Tender';
import { UrlHashBuilder } from '../../../../../../shared/utils/navigation/UrlHashBuilder';

export type SummarySheetRecapCellData = {
  tenderId: number;
  summarySheetRAGStatus: RAGStatus;
  ragGenerability: RagGenerability;
};

type IconProps = {
  color: MantineColor;
};

const useStyles = createStyles((theme, { color }: IconProps) => {
  let ringColor = '';
  let shade = 0;
  const iconSize = '20px';
  const rootSizeSpacing = '06';
  switch (color) {
    case 'green':
      ringColor = 'linear-gradient(311deg, #9BF0A9 10.88%, #FFF 87.06%);';
      shade = 7;
      break;
    case 'primary':
      ringColor = 'linear-gradient(311deg, #BEE2FF 10.88%, #FFF 87.06%);';
      shade = 7;
      break;
    case 'red':
      ringColor =
        'linear-gradient(311deg, #FB8A7B 10.88%, rgba(255, 255, 255, 0.00) 87.06%);';
      shade = 5;
      break;
    case 'gray':
      ringColor = 'linear-gradient(311deg, #EDEDF1 10.88%, #FFF 87.06%);';
      shade = 3;
      break;
    default:
      throw new Error('Invalid color');
  }
  return {
    root: {
      border: `1px solid ${theme.colors.gray[1]}`,
      borderRadius: '100%',
      width: theme.spacing[rootSizeSpacing],
      height: theme.spacing[rootSizeSpacing],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing['00'],
      margin: 'auto',
    },
    ring: {
      background: ringColor,
      borderRadius: '100%',
      margin: 'auto',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      color: theme.colors[color][shade],
      background: 'white',
      borderRadius: '100%',
      width: iconSize,
      height: iconSize,
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
});

export const SummarySheetRecapCell = ({
  tenderId,
  summarySheetRAGStatus,
  ragGenerability,
}: SummarySheetRecapCellData) => {
  if (summarySheetRAGStatus === 'DONE') {
    return <GenerationDoneIcon tenderId={tenderId} />;
  } else if (
    summarySheetRAGStatus === 'FAILED' ||
    summarySheetRAGStatus === 'NO_SUPPORTED_DOCUMENTS'
  ) {
    return <GenerationFailedIcon tenderId={tenderId} />;
  } else if (ragGenerability.isGeneratable) {
    return <DocumentsReadyIcon tenderId={tenderId} />;
  } else if (ragGenerability.error === 'NO_DOCUMENTS') {
    return <MissingDocumentsIcon tenderId={tenderId} />;
  }
  return <GenerationFailedIcon tenderId={tenderId} />;
};

function MissingDocumentsIcon({ tenderId }: { tenderId: number }) {
  const { t } = useTranslation('pipeline');
  return (
    <SummarySheetRecapIcon
      color="gray"
      icon={<IconFileReport size={16} />}
      tenderId={tenderId}
      hoverCardContent={t('cells.summarySheetRecap.hoverCard.missingDocuments')}
    />
  );
}

function DocumentsReadyIcon({ tenderId }: { tenderId: number }) {
  const { t } = useTranslation('pipeline');
  return (
    <SummarySheetRecapIcon
      color="primary"
      icon={<IconChecklist size={16} />}
      tenderId={tenderId}
      hoverCardContent={t('cells.summarySheetRecap.hoverCard.documentsReady')}
    />
  );
}

function GenerationDoneIcon({ tenderId }: { tenderId: number }) {
  const { t } = useTranslation('pipeline');
  return (
    <SummarySheetRecapIcon
      color="green"
      icon={<IconCheck size={16} />}
      tenderId={tenderId}
      hoverCardContent={t('cells.summarySheetRecap.hoverCard.generationDone')}
    />
  );
}

function GenerationFailedIcon({ tenderId }: { tenderId: number }) {
  const { t } = useTranslation('pipeline');
  return (
    <SummarySheetRecapIcon
      color="red"
      icon={<IconExclamationMark size={16} />}
      tenderId={tenderId}
      hoverCardContent={t('cells.summarySheetRecap.hoverCard.generationFailed')}
    />
  );
}

type SummarySheetRecapIconProps = {
  color: string;
  icon: React.ReactNode;
  tenderId: number;
  hoverCardContent: string;
};

function SummarySheetRecapIcon({
  color,
  icon,
  tenderId,
  hoverCardContent,
}: SummarySheetRecapIconProps) {
  const { classes } = useStyles({ color });
  const { redirectTo } = useUrlHash();

  return (
    <HoverCard withinPortal position="right-start">
      <HoverCard.Target>
        <div
          className={classes.root}
          onMouseDown={e => {
            e.preventDefault();
            e.stopPropagation();
            redirectTo(
              `/notices/${tenderId}?tab=summary-sheet`,
              new UrlHashBuilder().addLocationData().build(),
              undefined,
              e,
            );
          }}
        >
          <div className={classes.ring}>
            <div className={classes.icon}>{icon}</div>
          </div>
        </div>
      </HoverCard.Target>
      <HoverCard.Dropdown
        bg="gray.9"
        sx={theme => ({
          borderRadius: theme.radius.sm,
          padding: theme.spacing['02'],
          border: `1px solid ${theme.colors.gray[8]}`,
          boxShadow: theme.shadows.regular,
        })}
      >
        <Text variant="xs" fw={500} c="white">
          {hoverCardContent}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
