import { useMutation, useQueryClient } from '@tanstack/react-query';

import { rejectAllOpportunities } from '../../../../../shared/api/magellan/stream';
import { searchTenders } from '../../../../../shared/api/magellan/tender';

export const useRejectAllOpportunityMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (streamId: number) => rejectAllOpportunities(streamId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [searchTenders.name],
      });
    },
  });
};
