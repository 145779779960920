import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export type PanelContextValue<T> = {
  data: T;
  updateData: (newData: T) => void;
};

const PanelContext = createContext<PanelContextValue<any> | null>(null);

type SidepanelInternalDataProviderProps<T> = {
  children: React.ReactNode;
  initialData?: T;
  onDataUpdate: (newData: T) => void;
};

export const SidepanelInternalDataProvider = <T,>({
  children,
  initialData,
  onDataUpdate,
}: SidepanelInternalDataProviderProps<T>) => {
  const [data, setData] = useState<T | undefined>(initialData);

  const updateData = useCallback(
    (newData: T) => {
      setData(newData);
      onDataUpdate(newData);
    },
    [onDataUpdate],
  );
  const contextValue = useMemo(
    () => ({
      data,
      updateData,
    }),
    [data, updateData],
  );

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  return (
    <PanelContext.Provider value={contextValue}>
      {children}
    </PanelContext.Provider>
  );
};

export const useSidepanelContext = <T,>() => {
  const context = useContext(PanelContext);
  if (!context) {
    throw new Error(
      'useSidepanelContext must be used within a SidepanelProvider',
    );
  }
  return context as PanelContextValue<T>;
};
