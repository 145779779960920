import * as DocumentSidePanel from '../components/DocumentPreview/DocumentSidePanel';
import type { DocumentSidepanelContext } from '../components/DocumentPreview/DocumentSidePanel';
import { useSidepanel } from '../contexts/Sidepanel.provider';
import type { Document } from '../entities/Document';

export function useDocumentSidePanel({ documents }: { documents: Document[] }) {
  const { togglePanel, getPanelContext } =
    useSidepanel<DocumentSidepanelContext>({});
  const panelContext = getPanelContext();

  const openPanel = (selectedDocument: Document) => {
    togglePanel({
      header: <DocumentSidePanel.Header />,
      content: <DocumentSidePanel.Content />,
      initialData: {
        documents,
        selectedDocument,
      },
    });
  };

  return { panelContext, openPanel };
}
