import type Stream from '../../../entities/Stream';
import { magellanClient } from '../../../infra/axios';
import type { GetAllStreamsResponseDTO } from './dto';

export async function createStream(stream: Stream): Promise<Stream> {
  const response = await magellanClient.post<Stream>('/streams', stream);
  return response.data;
}

export async function updateStream(
  id: string,
  stream: Stream,
): Promise<Stream> {
  const response = await magellanClient.put<Stream>(`/streams/${id}`, stream);
  return response.data;
}

export async function duplicateStream(id: number): Promise<Stream> {
  const response = await magellanClient.post<Stream>(
    `/streams/${id}/duplicate`,
  );
  return response.data;
}

export async function deleteStream(id: number): Promise<Stream> {
  const response = await magellanClient.delete<Stream>(`/streams/${id}`);
  return response.data;
}

export async function getStreams(): Promise<GetAllStreamsResponseDTO> {
  const response =
    await magellanClient.get<GetAllStreamsResponseDTO>('/streams');
  return response.data;
}

export async function getStream(id: number): Promise<Stream> {
  const response = await magellanClient.get(`/streams/${id}`);
  const {
    afterPublicationDate,
    beforePublicationDate,
    afterResponseDeadlineDate,
    beforeResponseDeadlineDate,
  } = response.data.filterSettings;

  const stream = {
    ...response.data,
    filterSettings: {
      ...response.data.filterSettings,
      afterPublicationDate: afterPublicationDate
        ? new Date(afterPublicationDate)
        : null,
      beforePublicationDate: beforePublicationDate
        ? new Date(beforePublicationDate)
        : null,
      afterResponseDeadlineDate: afterResponseDeadlineDate
        ? new Date(afterResponseDeadlineDate)
        : null,
      beforeResponseDeadlineDate: beforeResponseDeadlineDate
        ? new Date(beforeResponseDeadlineDate)
        : null,
    },
  };

  return stream;
}

export async function rejectAllOpportunities(streamId: number): Promise<void> {
  const response = await magellanClient.post(
    `/streams/${streamId}/rejectAllOpportunities`,
  );
  return response.data;
}
