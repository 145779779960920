import { useEffect } from 'react';

import { createStyles } from '@mantine/core';
import { Text } from '@mantine/core';
import { useIntersection } from '@mantine/hooks';

const useStyles = createStyles(theme => ({
  loadingText: {
    textAlign: 'center',
    marginBottom: theme.spacing['04'],
    fontWeight: 400,
  },
}));

export function InfiniteScroll({
  isFetching,
  fetchNextPage,
  hasNextPage,
}: {
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
}) {
  const { classes } = useStyles();

  const { ref, entry } = useIntersection({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });

  useEffect(() => {
    if (entry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, entry?.isIntersecting, hasNextPage]);

  if (!hasNextPage) {
    return null;
  }

  if (isFetching) {
    return (
      <Text className={classes.loadingText} variant="sm">
        Chargement...
      </Text>
    );
  }

  return <div ref={ref} onClick={() => fetchNextPage()} />;
}
