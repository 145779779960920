import { forwardRef, useState } from 'react';

import { Box, Group, Indicator, Popover, Text } from '@mantine/core';
import { useHover } from '@mantine/hooks';

import { IconMessageCircle2, IconNote } from '@tabler/icons-react';

import { useUrlHash } from '../../../../../../shared/contexts/UrlHash.provider';
import { UrlHashBuilder } from '../../../../../../shared/utils/navigation/UrlHashBuilder';
import { Notes } from '../../../../../collaboration/components/notes/Notes';

type CollaborationCellProps = {
  tenderId: number;
  note: string;
  numberOfComments: number;
};

export function CollaborationCell({
  tenderId,
  note,
  numberOfComments,
}: CollaborationCellProps) {
  const [open, setOpen] = useState(false);
  const { hovered, ref } = useHover();

  const hoverButton = (
    <Group ref={ref}>
      <Group
        noWrap
        spacing="02"
        c="gray.6"
        p="02"
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          e.stopPropagation(), setOpen(true);
        }}
        sx={theme => ({
          visibility: hovered ? 'visible' : 'hidden',
          cursor: 'pointer',
          borderRadius: theme.radius.sm,
        })}
      >
        <IconNote size={16} />
        <Text variant="sm" fw={400} c="gray.6">
          Ajouter une note
        </Text>
      </Group>
    </Group>
  );

  return (
    <Popover
      closeOnClickOutside
      closeOnEscape
      position="bottom-end"
      arrowPosition="center"
      radius="lg"
      width={300}
      trapFocus
      withArrow
      shadow="md"
      opened={open}
      onChange={setOpen}
      withinPortal
    >
      <Popover.Target>
        {note || numberOfComments ? (
          <ContributionGroup
            tenderId={tenderId}
            onNoteClick={() => setOpen(true)}
            hasNote={!!note}
            numberOfComments={numberOfComments}
          />
        ) : (
          hoverButton
        )}
      </Popover.Target>
      <Popover.Dropdown
        onMouseDown={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
        sx={theme => ({
          background: theme.white,
          maxHeight: '400px',
          overflowY: 'auto',
        })}
      >
        <Notes
          tenderId={tenderId}
          initialNote={note}
          close={() => setOpen(false)}
          withHeader
        />
      </Popover.Dropdown>
    </Popover>
  );
}

type ContributionGroupProps = {
  onNoteClick: () => void;
  tenderId: number;
  hasNote: boolean;
  numberOfComments: number;
};

const ContributionGroup = forwardRef<HTMLDivElement, ContributionGroupProps>(
  (
    {
      onNoteClick: openNotePopover,
      tenderId,
      hasNote,
      numberOfComments,
    }: ContributionGroupProps,
    ref,
  ) => {
    const [isHovered, setHovered] = useState(false);

    return (
      <Group
        ref={ref}
        w="100%"
        noWrap
        spacing="02"
        c="gray.6"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <NoteWithCounter
          onClick={openNotePopover}
          hasNote={hasNote}
          isHovered={isHovered}
        />
        <CommentWithCounter
          tenderId={tenderId}
          numberOfComments={numberOfComments}
        />
      </Group>
    );
  },
);

type CommentWithCounterProps = {
  tenderId: number;
  numberOfComments: number;
};

const CommentWithCounter = forwardRef<HTMLDivElement, CommentWithCounterProps>(
  ({ tenderId, numberOfComments }: CommentWithCounterProps, ref) => {
    const { redirectTo } = useUrlHash();
    if (!numberOfComments) {
      return;
    }

    return (
      <Group
        ref={ref}
        noWrap
        spacing="01"
        p="01"
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          redirectTo(
            `/notices/${tenderId}`,
            new UrlHashBuilder()
              .addLocationData()
              .addCollaborationActionData('comments')
              .build(),
            undefined,
            e,
          );
        }}
        sx={theme => ({
          cursor: 'pointer',
          borderRadius: theme.radius.md,
          ':hover': {
            background: theme.colors.gray[1],
            color: theme.colors.gray[7],
          },
        })}
      >
        <IconMessageCircle2 size={16} />
        <Text variant="sm" fw={400}>
          {numberOfComments}
        </Text>
      </Group>
    );
  },
);

type NoteWithCounterProps = {
  onClick: () => void;
  hasNote: boolean;
  isHovered: boolean;
};

const NoteWithCounter = forwardRef<HTMLDivElement, NoteWithCounterProps>(
  (
    { onClick: openNotePopover, hasNote, isHovered }: NoteWithCounterProps,
    ref,
  ) => {
    if (!hasNote && !isHovered) {
      return <Box w="05" />;
    }
    return (
      <Indicator
        ref={ref}
        p="01"
        pb={0}
        size={8}
        withBorder
        color="primary.6"
        disabled={!hasNote}
        onMouseDown={e => e.stopPropagation()}
        onClick={e => {
          e.stopPropagation();
          openNotePopover();
        }}
        styles={{
          indicator: {
            marginTop: '8px',
            marginRight: '6px',
          },
        }}
        sx={theme => ({
          cursor: 'pointer',
          borderRadius: theme.radius.md,
          ':hover': {
            background: theme.colors.gray[1],
            color: theme.colors.gray[7],
          },
        })}
      >
        <IconNote size={16} style={{ marginTop: '1px' }} />
      </Indicator>
    );
  },
);
