import * as dateUtils from '../../../../../shared/utils/dates';
import { TenderStatus } from '../../../../../shared/entities/Tender';

export function tenderStatusToLabel(status: TenderStatus) {
  switch (status) {
    case TenderStatus.OPEN:
      return 'Ouvert';
    case TenderStatus.CLOSED:
      return 'Fermé';
  }
}

export function dateFilterToValue(value?: string[]) {
  // This function is used to convert the date filter value (string[]) to a Date object.
  if (!value) return;

  return dateUtils.parseFrLocaleDateStringToDate(value[0]);
}
