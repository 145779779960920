import type {
  DecisionRenewalStatus,
  DecisionStatus,
} from '../../../../../shared/entities/Interaction';
import type Tender from '../../../../../shared/entities/Tender';

export function updatePageTenders(params: {
  decision?: DecisionStatus;
  renewalStatus?: DecisionRenewalStatus;
  reason?: string;
  tenderId: number;
}) {
  return (page: any) => {
    return {
      ...page,
      results: page.results.map(updateTender({ ...params })),
    };
  };
}

type UpdateTenderParams = {
  decision?: DecisionStatus;
  renewalStatus?: DecisionRenewalStatus;
  reason?: string;
  tenderId: number;
};

function updateTender({
  decision,
  renewalStatus,
  reason,
  tenderId,
}: UpdateTenderParams) {
  return (tender: Tender) => {
    if (tender.id === tenderId) {
      return {
        ...tender,
        interaction: {
          ...tender.interaction,
          decisionStatus: decision || tender.interaction?.decisionStatus,
          decisionRenewalStatus:
            renewalStatus || tender.interaction?.decisionRenewalStatus,
          reason,
        },
      };
    }
    return tender;
  };
}
