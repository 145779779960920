import { Group, Stack } from '@mantine/core';

import * as FilterTags from './FilterTags';
import * as Filters from './Filters';
import * as SkeletonLoader from './SkeletonLoader';
import type { TenderStatus } from '../../../../../shared/entities/Tender';
import { usePipelineFilters } from '../../../hooks/usePipelineFilters.hook';
import { useTendersWithInteractionQuery } from '../../../hooks/useTendersWithInteractionQuery.hook';
import { TableColumn } from '../utils/columnDefinitions';
import { FiltersWrapper } from './FilterWrapper';
import { GlobalSearchInput } from './GlobalSearchInput';

export function PipelineFilters() {
  const {
    filters,
    setStatusFilter,
    setResponseDeadlineFilter,
    setOwnerFilter,
    resetFilters,
    removeFilter,
    isLoaded,
  } = usePipelineFilters();

  const tenderDecisionsQuery = useTendersWithInteractionQuery();

  if (!isLoaded) {
    return <SkeletonLoader.FilterBar />;
  }

  const rowCount =
    tenderDecisionsQuery.data?.pages[0].pagination.totalResults ?? 0;

  return (
    <Stack mx="06" pt="03" spacing="03">
      <Group spacing={0} position="apart">
        <FiltersWrapper rowCount={rowCount}>
          <Filters.Status filters={filters} onChange={setStatusFilter} />

          <Filters.ResponseDeadline
            filters={filters}
            onChange={setResponseDeadlineFilter}
          />
          <Filters.Owner filters={filters} onChange={setOwnerFilter} />

          <Filters.ResetButton filters={filters} resetFilters={resetFilters} />
        </FiltersWrapper>

        <GlobalSearchInput />
      </Group>

      <Group>
        <FilterTags.Owner
          values={filters[TableColumn.OWNER]}
          removeFilter={removeFilter}
        />
        <FilterTags.Status
          values={filters[TableColumn.STATUS] as TenderStatus[]}
          removeFilter={removeFilter}
        />
        <FilterTags.ResponseDeadline
          value={filters[TableColumn.RESPONSE_DEADLINE]}
          removeFilter={removeFilter}
        />
      </Group>
    </Stack>
  );
}
