import { useState } from 'react';

import { IconSearch } from '@tabler/icons-react';

import { TextInput } from '../../../../../shared/components/UI/TextInput/TextInput';

import { useGlobalSearchFilter } from '../../../hooks/useGlobalSearchFilter.hook';

export function GlobalSearchInput() {
  const [isFocused, setIsFocused] = useState(false);
  const { setGlobalSearchFilter, globalSearchFilter } = useGlobalSearchFilter();

  return (
    <TextInput
      variant="filled"
      size="xs"
      w={isFocused ? `300px` : `200px`}
      placeholder="Rechercher..."
      icon={<IconSearch />}
      value={globalSearchFilter}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setGlobalSearchFilter(event.target.value);
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      sx={{ transition: 'width 0.5s' }}
    />
  );
}
