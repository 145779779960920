import type { MantineTheme } from '@mantine/core';
import { Box, Group, Menu, Text, useMantineTheme } from '@mantine/core';
import { modals } from '@mantine/modals';
import type { MRT_Cell } from 'mantine-react-table';

import {
  IconArrowBackUp,
  IconArrowRight,
  IconCheck,
  IconDotsVertical,
  IconExternalLink,
  IconPencil,
  IconTrophy,
  IconTrophyOff,
  IconX,
} from '@tabler/icons-react';

import type { TengoModals } from '../../../../../../shared/contexts/Modals.provider';
import {
  DecisionRenewalStatus,
  DecisionStatus,
} from '../../../../../../shared/entities/Interaction';
import type { RagGenerability } from '../../../../../../shared/entities/Tender';
import {
  useDecisionMutation,
  useRenewalDecisionMutation,
} from '../../hooks/useDecisionMutation.hook';

type ActionCellProps = {
  cell: MRT_Cell<any>;
};

export type ActionCellData = {
  tenderId: number;
  decisionStatus: DecisionStatus;
  renewalStatus: DecisionRenewalStatus;
  buyerInfiniteQueryKey: string;
  isRenewal: boolean;
  ragGenerability: RagGenerability;
};

export function ActionCell({ cell }: ActionCellProps) {
  const {
    tenderId,
    decisionStatus,
    renewalStatus,
    buyerInfiniteQueryKey,
    isRenewal,
    ragGenerability,
  } = cell.getValue<ActionCellData>();

  const theme = useMantineTheme();
  const queryKey = JSON.parse(buyerInfiniteQueryKey);

  const decisionMutation = useDecisionMutation(
    tenderId,
    queryKey,
    ragGenerability,
  );

  const renewalDecisionMutation = useRenewalDecisionMutation(
    tenderId,
    queryKey,
    ragGenerability,
  );

  const onTenderDecision = (
    status: DecisionStatus,
    reason?: string,
    winningAmount?: number,
  ) => {
    decisionMutation.mutate({ decision: status, reason, winningAmount });
  };

  const onRenewalDecision = (
    status: DecisionRenewalStatus,
    reason?: string,
  ) => {
    renewalDecisionMutation.mutate({ renewalStatus: status, reason });
  };
  const decisionButtons = getDecisionActionButtonSettings(
    theme,
    decisionStatus,
    onTenderDecision,
  );
  const renewalActionButtons = getRenewalActionButtonSettings(
    theme,
    renewalStatus,
    onRenewalDecision,
  );

  const actionButtons = isRenewal ? renewalActionButtons : decisionButtons;

  return (
    <Menu shadow="md" withinPortal position="bottom-end">
      <Menu.Target>
        <Box
          w="06"
          h="06"
          p="02"
          onMouseDown={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
          sx={theme => ({
            ':hover': {
              cursor: 'pointer',
              background: theme.colors.gray[2],
              borderRadius: theme.radius.sm,
            },
          })}
        >
          <IconDotsVertical size={16} />
        </Box>
      </Menu.Target>

      <Menu.Dropdown
        onMouseDown={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        {actionButtons?.map((actionButton, index) => (
          <Menu.Item
            key={index}
            onMouseDown={e => e.stopPropagation()}
            onClick={actionButton.onClick}
          >
            <Group>
              {actionButton.icon}
              <Text variant="sm" fw="400" c="dark.9">
                {actionButton.label}
              </Text>
            </Group>
          </Menu.Item>
        ))}
        <Menu.Item
          onMouseDown={e => e.stopPropagation()}
          onClick={() => window.open(`/notices/${tenderId}`)}
        >
          <Group>
            <IconExternalLink size={16} />
            <Text variant="sm" fw="400" c="dark.9">
              Voir l'opportunité
            </Text>
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

type DecisionActionButton = {
  icon: React.ReactElement;
  label: string;
  onClick?: () => void;
};

const getDecisionActionButtonSettings = (
  theme: MantineTheme,
  decisionStatus: DecisionStatus,
  onTenderDecision: (status: DecisionStatus, reason?: string) => void,
): DecisionActionButton[] => {
  const openModal = (nextStatus: DecisionStatus, modalToOpen: TengoModals) =>
    modals.openContextModal({
      modal: modalToOpen,
      innerProps: {
        onDecision: onTenderDecision,
        nextStatus,
      },
    });

  switch (decisionStatus) {
    case DecisionStatus.PENDING:
      return [
        {
          icon: <IconCheck size={16} color={theme.colors.primary[7]} />,
          label: 'À analyser',
          onClick: () => onTenderDecision(DecisionStatus.TO_ANALYZE),
        },
        {
          icon: <IconX size={16} color={theme.colors.red[6]} />,
          label: 'Rejeter',
          onClick: () => onTenderDecision(DecisionStatus.REJECTED),
        },
      ];
    case DecisionStatus.REJECTED:
      return [
        {
          icon: <IconCheck size={16} color={theme.colors.primary[7]} />,
          label: 'À analyser',
          onClick: () => onTenderDecision(DecisionStatus.TO_ANALYZE),
        },
      ];
    case DecisionStatus.TO_ANALYZE:
      return [
        {
          icon: <IconArrowRight size={16} color={theme.colors.primary[7]} />,
          label: 'Placer en go',
          onClick: () => onTenderDecision(DecisionStatus.GO),
        },
        {
          icon: <IconX size={16} color={theme.colors.red[6]} />,
          label: 'Placer en No go',
          onClick: () => openModal(DecisionStatus.NOGO, 'nogoReason'),
        },
      ];
    case DecisionStatus.GO:
      return [
        {
          icon: <IconPencil size={16} color={theme.colors.primary[7]} />,
          label: 'Marqué comme déposée',
          onClick: () => onTenderDecision(DecisionStatus.ANSWERED),
        },
      ];
    case DecisionStatus.NOGO:
      return [
        {
          icon: <IconArrowRight size={16} color={theme.colors.primary[7]} />,
          label: 'Placer en go',
          onClick: () => onTenderDecision(DecisionStatus.GO),
        },
      ];
    case DecisionStatus.ANSWERED:
      return [
        {
          icon: <IconTrophy size={16} color={theme.colors.primary[7]} />,
          label: 'Marqué comme gagné',
          onClick: () => openModal(DecisionStatus.WIN, 'winReason'),
        },
        {
          icon: <IconTrophyOff size={16} color={theme.colors.red[6]} />,
          label: 'Marqué comme perdu',
          onClick: () => openModal(DecisionStatus.LOSS, 'lossReason'),
        },
      ];
    case DecisionStatus.WIN:
      return [
        {
          icon: <IconTrophyOff size={16} color={theme.colors.red[6]} />,
          label: 'Marqué comme perdu',
          onClick: () => openModal(DecisionStatus.LOSS, 'lossReason'),
        },
      ];
    case DecisionStatus.LOSS:
      return [
        {
          icon: <IconTrophy size={16} color={theme.colors.primary[7]} />,
          label: 'Marqué comme gagné',
          onClick: () => openModal(DecisionStatus.WIN, 'winReason'),
        },
      ];
    default:
      return [
        {
          icon: <IconCheck size={16} color={theme.colors.primary[7]} />,
          label: 'À analyser',
          onClick: () => onTenderDecision(DecisionStatus.TO_ANALYZE),
        },
        {
          icon: <IconX size={16} color={theme.colors.red[6]} />,
          label: 'Rejeter',
          onClick: () => onTenderDecision(DecisionStatus.REJECTED),
        },
      ];
  }
};

const getRenewalActionButtonSettings = (
  theme: MantineTheme,
  decisionStatus: DecisionRenewalStatus,
  onTenderDecision: (status: DecisionRenewalStatus, reason?: string) => void,
): DecisionActionButton[] => {
  switch (decisionStatus) {
    case DecisionRenewalStatus.PENDING:
      return [
        {
          icon: <IconCheck size={16} color={theme.colors.primary[7]} />,
          label: 'À analyser',
          onClick: () => onTenderDecision(DecisionRenewalStatus.TO_ANALYZE),
        },
        {
          icon: <IconX size={16} color={theme.colors.red[6]} />,
          label: 'Rejeter',
          onClick: () => onTenderDecision(DecisionRenewalStatus.REJECTED),
        },
      ];
    case DecisionRenewalStatus.REJECTED:
      return [
        {
          icon: <IconCheck size={16} color={theme.colors.primary[7]} />,
          label: 'À analyser',
          onClick: () => onTenderDecision(DecisionRenewalStatus.TO_ANALYZE),
        },
        {
          icon: <IconArrowBackUp size={16} color={theme.colors.gray[6]} />,
          label: 'Annuler la décision',
          onClick: () => onTenderDecision(DecisionRenewalStatus.PENDING),
        },
      ];
    case DecisionRenewalStatus.TO_ANALYZE:
      return [
        {
          icon: <IconX size={16} color={theme.colors.red[6]} />,
          label: 'Rejeter',
          onClick: () => onTenderDecision(DecisionRenewalStatus.REJECTED),
        },
        {
          icon: <IconArrowBackUp size={16} color={theme.colors.gray[6]} />,
          label: 'Annuler la décision',
          onClick: () => onTenderDecision(DecisionRenewalStatus.PENDING),
        },
      ];
    default:
      return [
        {
          icon: <IconCheck size={16} color={theme.colors.primary[7]} />,
          label: 'À analyser',
          onClick: () => onTenderDecision(DecisionRenewalStatus.TO_ANALYZE),
        },
        {
          icon: <IconX size={16} color={theme.colors.red[6]} />,
          label: 'Rejeter',
          onClick: () => onTenderDecision(DecisionRenewalStatus.REJECTED),
        },
      ];
  }
};
