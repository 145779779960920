
import { Group, Switch, Text, Box, Stack } from '@mantine/core';
import { IconAlertTriangle, IconInfoSmall } from '@tabler/icons-react';
import TooltipWithIcon from './TooltipWithIcon';
import { useTranslation } from 'react-i18next';


type TendersWithoutCPVsSwitchContainerProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export function TendersWithoutCPVsSwitchContainer({
  value,
  onChange,
}: TendersWithoutCPVsSwitchContainerProps) {
  const { t } = useTranslation('stream');

  return (
    <Stack spacing="02">
      <Group w="100%" spacing="02" noWrap>
        <Group noWrap spacing="01">
          <Text variant="sm" fw="400" c="gray.5">
            {t('cpv.includeTendersWithoutCPV')}
          </Text>
          <TooltipWithIcon
            color="gray.4"
            icon={<Box w="05" h="05">
              <IconInfoSmall size={23} stroke={2} />
            </Box>
            }
            content={t('cpv.includeTendersWithoutCPVSwitchTooltip')}
          />
        </Group>
        <TendersWithoutCPVsSwitch
          value={value}
          onChange={onChange}
        />
      </Group>
      {value === false && (
        <TendersWithoutCPVsSwitchWarning />
      )}
    </Stack>
  );
}

function TendersWithoutCPVsSwitch({
  value,
  onChange,
}: TendersWithoutCPVsSwitchContainerProps) {

  return (
    <Group
      noWrap
      position="apart"
      onClick={() => onChange(!value)}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Switch
        checked={value}
        onClick={e => {
          e.stopPropagation();
        }}
      />
    </Group>
  );
}

function TendersWithoutCPVsSwitchWarning() {
  const { t } = useTranslation('stream');

  return (
    <Group spacing={'02'}>
      <Box>
        <Text c="yellow.9" size="xs"><IconAlertTriangle size={16} stroke={2} /></Text>
      </Box>
      <Box>
        <Text c="yellow.9" size="xs">
          {t('cpv.includeTendersWithoutCPVSwitchWarning')}
        </Text>
      </Box>
    </Group>
  );
}
