import { useState } from 'react';

import { Group, Stack, Text, Title } from '@mantine/core';

import AIIconSparkle from '../../../../shared/assets/ai_icons/sparkle.svg?react';
import type {
  JudgementCriterion,
  requirementEvaluation,
} from '../../../../shared/entities/BidResponse';
import { BidRequirementEvaluationGrid } from './BidRequirementEvaluationGrid';
import { GridFilters } from './GridFilters';
import { SummaryCard } from './SummaryCard';
import { useProposalVersion } from './useProposalVersion.hook';

export const MISSING_EVALUATION =
  "Nous pensons que votre mémoire technique ne remonte pas l'information.";

type SimulationTabProps = {
  bidResponseId: number;
  proposalVersionId: number;
  criteria: JudgementCriterion[];
};

export function SimulationTab({
  bidResponseId,
  proposalVersionId,
  criteria,
}: SimulationTabProps) {
  const { proposalVersion } = useProposalVersion(
    bidResponseId,
    proposalVersionId,
  );
  const missingRequirements = proposalVersion.requirementEvaluations?.filter(
    e => e.retrievedAnswerFromProposal === MISSING_EVALUATION,
  );

  return (
    <Stack spacing="06" p="06">
      <SummaryCard
        total={proposalVersion.requirementEvaluations?.length || 0}
        missing={missingRequirements?.length || 0}
        criteria={criteria}
      />
      <BuyerEvaluationsCard
        proposalVersionId={proposalVersionId}
        bidResponseId={bidResponseId}
        judgementCriteria={criteria}
        requirementEvaluations={proposalVersion.requirementEvaluations || []}
      />
    </Stack>
  );
}

type BuyerEvaluationsCardProps = {
  bidResponseId: number;
  proposalVersionId: number;
  requirementEvaluations: requirementEvaluation[];
  judgementCriteria: JudgementCriterion[];
};

export type RequirementEvaluationFilter = {
  callback: (e: requirementEvaluation) => boolean;
  type: string;
};

const BuyerEvaluationsCard = ({
  bidResponseId,
  proposalVersionId,
  requirementEvaluations,
  judgementCriteria,
}: BuyerEvaluationsCardProps) => {
  const [filter, setFilter] = useState<RequirementEvaluationFilter[] | null>(
    null,
  );
  const handleStoreCallback = (callBack: RequirementEvaluationFilter) => {
    setFilter(prev => (prev ? [...prev, callBack] : [callBack]));
  };
  const handleRemoveCallback = (type: RequirementEvaluationFilter['type']) => {
    setFilter(prev => {
      if (!prev) {
        return null;
      }
      const newFilter = prev.filter(f => f.type !== type);
      return newFilter.length === 0 ? null : newFilter;
    });
  };

  const clearFilter = () => setFilter(null);

  return (
    <Stack
      p="05"
      spacing="05"
      sx={theme => ({
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: theme.radius.md,
      })}
    >
      <CardHeader />
      <GridFilters
        filter={!!filter}
        setFilter={handleStoreCallback}
        removeFilter={handleRemoveCallback}
        clearFilter={clearFilter}
        missingElements={0}
        judgementCriteria={judgementCriteria}
        requirementEvaluations={requirementEvaluations}
      />
      <BidRequirementEvaluationGrid
        bidResponseId={bidResponseId}
        proposalVersionId={proposalVersionId}
        filterFunction={filter?.map(f => f.callback) || null}
        requirementEvaluations={requirementEvaluations}
        judgementCriteria={judgementCriteria}
      />
    </Stack>
  );
};

const CardHeader = () => {
  return (
    <Stack spacing="00">
      <Group noWrap spacing={0}>
        <Title order={4}>Grille d'analyse estimée de l'acheteur</Title>

        <AIIconSparkle style={{ minWidth: '30px' }} />
      </Group>
      <Text variant="sm" fw="400" c="gray.5">
        Découvrez si votre mémoire technique correspond aux attentes de
        l'acheteur
      </Text>
    </Stack>
  );
};
