import {
  DecisionRenewalStatus,
  DecisionStatus,
} from '../../../entities/Interaction';
import type { Interaction } from '../../../entities/Interaction';
import type { TenderStatus } from '../../../entities/Tender';
import { magellanClient } from '../../../infra/axios';
import type {
  GetDecisionCountsResponseDTO,
  GetTendersWithInteractionResponseDTO,
  UpdateInteractionRequestDTO,
} from './dto';

export async function upsertInteractionWithDecisionStatus(
  tenderId: number,
  status: DecisionStatus,
  streamId?: number,
): Promise<Interaction> {
  const payload = streamId ? { status, streamId } : { status };

  const response = await magellanClient.post<Interaction>(
    `tenders/${tenderId}/interactions/decisionStatus`,
    payload,
  );

  return response.data;
}

export async function upsertInteractionWithRenewalDecisionStatus(
  tenderId: number,
  renewalStatus: DecisionRenewalStatus,
): Promise<Interaction> {
  const response = await magellanClient.post<Interaction>(
    `tenders/${tenderId}/interactions/decisionRenewalStatus`,
    { status: renewalStatus },
  );

  return response.data;
}

type GetTendersWithInteractionParams = Partial<{
  decisionStatus: DecisionStatus;
  tenderStatus: TenderStatus;
  ownerId: string;
  responseDeadline: Date;
  take: number;
  skip: number;
  q: string;
}>;

export async function getTendersWithInteraction({
  decisionStatus,
  tenderStatus,
  ownerId,
  responseDeadline,
  take,
  skip,
  q,
}: GetTendersWithInteractionParams): Promise<GetTendersWithInteractionResponseDTO> {
  const response =
    await magellanClient.get<GetTendersWithInteractionResponseDTO>(
      `/interactions`,
      {
        params: {
          ...(decisionStatus && { decisionStatus }),
          ...(tenderStatus && { tenderStatus }),
          ...(ownerId && { ownerId }),
          ...(responseDeadline && {
            responseDeadline: responseDeadline.getTime(),
          }),
          ...(decisionStatus === DecisionStatus.ANSWERED && {
            orderBy: 'answeredAt',
          }),
          ...(q && { q }),
          take,
          skip,
        },
      },
    );

  return response.data;
}

type GetRenewalTendersWithInteractionParams = Partial<{
  decisionRenewalStatus: DecisionRenewalStatus;
  ownerId: string;
  take: number;
  skip: number;
  q: string;
}>;

export async function getRenewalTendersWithInteraction({
  decisionRenewalStatus,
  ownerId,
  take,
  skip,
  q,
}: GetRenewalTendersWithInteractionParams): Promise<GetTendersWithInteractionResponseDTO> {
  const response =
    await magellanClient.get<GetTendersWithInteractionResponseDTO>(
      `/interactions/renewals`,
      {
        params: {
          ...(decisionRenewalStatus && { decisionRenewalStatus }),
          ...(ownerId && { ownerId }),
          ...(decisionRenewalStatus === DecisionRenewalStatus.TO_ANALYZE && {
            orderBy: 'answeredAt',
          }),
          ...(q && { q }),
          take,
          skip,
        },
      },
    );

  return response.data;
}

export async function getDecisionCounts(): Promise<GetDecisionCountsResponseDTO> {
  const response =
    await magellanClient.get<GetDecisionCountsResponseDTO>(
      `/interactions/counts`,
    );

  return response.data;
}

export async function updateInteraction(
  tenderId: number,
  updateInteractionRequestDTO: UpdateInteractionRequestDTO,
): Promise<Interaction> {
  const response = await magellanClient.put<Interaction>(
    `/tenders/${tenderId}/interactions`,
    updateInteractionRequestDTO,
  );

  return response.data;
}
