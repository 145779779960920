import { Suspense, useEffect, useState } from 'react';

import { Box, Group, Stack, Switch, Text } from '@mantine/core';

import {
  IconAlignJustified,
  IconChecklist,
  IconFolder,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Loader } from '../../shared/components/UI/Loader/Loader';
import { NAVBAR_HEIGHT } from '../../shared/components/UI/Navbar/Navbar';
import { TabsComponents } from '../../shared/components/UI/Tabs/Tabs';

import type { CollaborationTabValues } from '../../features/collaboration/components/Header';
import { SidePanel } from '../../features/collaboration/components/SidePanel';
import { Header } from '../../features/notice/components/Header';
import { TabLoader } from '../../features/notice/components/TabLoader';
import DocumentsTab from '../../features/notice/components/tabs/DocumentsTab';
import InfoTab from '../../features/notice/components/tabs/InfoTab';
import { SummarySheetTab } from '../../features/notice/components/tabs/SummarySheetTab';
import {
  NoticeProvider,
  useNoticeContext,
} from '../../features/notice/contexts/Notice.provider';
import { useUpdateSubscriptionStatusMutation } from '../../features/notice/hooks/useUpdateSubscriptionStatusMutation.hook';
import { HeaderActionBar } from '../../shared/components/HeaderActionBar';
import { useUrlHash } from '../../shared/contexts/UrlHash.provider';
import { DceRequestStatus } from '../../shared/entities/DceRequestStatus';
import { useSetPageTitle } from '../../shared/hooks/useSetPageTitle.hook';
import { useTenderQuery } from '../../shared/hooks/useTenderQuery.hook';
import { useUsersQuery } from '../../shared/hooks/useUsersQuery.hook';
import { UrlHashTypes } from '../../shared/utils/navigation/UrlHashBuilder';

export const NoticeTabValues = {
  INFORMATIONS: 'informations',
  DOCUMENTS: 'documents',
  SUMMARY_SHEET: 'summary-sheet',
} as const;

export type NoticeTabValues =
  (typeof NoticeTabValues)[keyof typeof NoticeTabValues];

const Tabs = TabsComponents<NoticeTabValues>();

export type PageParams = {
  id: string;
  tabParam?: NoticeTabValues;
};

export default function NoticePage() {
  const { t } = useTranslation(['notice', 'common']);
  const initialSidePanelTab = useUrlHash().getReceivedUrlHashParam(
    UrlHashTypes.COLLABORATION_ACTION,
  );
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlTabsValue = queryParams.get('tab') as NoticeTabValues | null;
  const defaultTab = NoticeTabValues.INFORMATIONS;
  const [noticeTab, setNoticeTab] = useState<NoticeTabValues>(
    urlTabsValue || defaultTab,
  );
  const usersQuery = useUsersQuery();
  const [sidePanelOpen, setSidePanelOpen] =
    useState<boolean>(!!initialSidePanelTab);
  const [activeSidePanelTab, setActiveSidePanelTab] =
    useState<CollaborationTabValues | null>(initialSidePanelTab);
  const { id } = useParams<PageParams>() as PageParams; // Casting to enforce type. See https://github.com/remix-run/react-router/issues/8498
  const [dceRequestStatus, setDceRequestStatus] = useState<DceRequestStatus>(
    DceRequestStatus.WAITING,
  );

  useEffect(() => {
    setNoticeTab(urlTabsValue || defaultTab);
  }, [defaultTab, urlTabsValue]);

  useEffect(() => {
    setActiveSidePanelTab(initialSidePanelTab);
    setSidePanelOpen(!!initialSidePanelTab);
  }, [initialSidePanelTab]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [noticeTab]);

  const toggleSidePanel = () => setSidePanelOpen(prev => !prev);
  const setActiveSidebarTab = (tab: CollaborationTabValues | null) => {
    setActiveSidePanelTab(tab);
    if (tab === null) {
      setSidePanelOpen(false);
    }
    if (!sidePanelOpen) {
      toggleSidePanel();
    }
  };

  const tenderQuery = useTenderQuery(Number(id));

  useEffect(() => {
    if (tenderQuery.data?.dceRequestStatus) {
      setDceRequestStatus(tenderQuery.data.dceRequestStatus);
    }
  }, [tenderQuery.data?.dceRequestStatus]);

  const tender = tenderQuery.data;
  useSetPageTitle(tender?.title ? `AO - ${tender?.title}` : 'Tengo');

  if (
    tenderQuery.isPending ||
    tenderQuery.isLoading ||
    !tender ||
    usersQuery.isLoading
  ) {
    return <Loader title="Chargement" />;
  }

  if (tenderQuery.isError) {
    throw tenderQuery.error;
  }

  let content;
  switch (noticeTab) {
    case NoticeTabValues.INFORMATIONS:
      content = <InfoTab />;
      break;
    case NoticeTabValues.DOCUMENTS:
      content = (
        <DocumentsTab
          dceRequestStatus={dceRequestStatus}
          setDceRequestStatus={setDceRequestStatus}
        />
      );
      break;
    case NoticeTabValues.SUMMARY_SHEET:
      content = <SummarySheetTab />;
      break;
    default:
      throw new Error(`Unknown tab value: ${noticeTab}`);
  }

  return (
    <NoticeProvider tender={tender}>
      <Stack spacing="05">
        <Stack
          spacing={0}
          pos="sticky"
          top={NAVBAR_HEIGHT}
          px="07"
          sx={theme => ({
            background: theme.other.gradients.backgroundMedium,
            zIndex: 10,
          })}
        >
          <HeaderActionBar
            owner={tender.interaction?.owner || null}
            users={usersQuery.data}
            setActiveTab={setActiveSidebarTab}
            isSidePanelOppenned={sidePanelOpen}
            tender={tender}
          />
          <Box
            pos="relative"
            sx={theme => ({
              '::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 16,
                height: '20%',
                width: '50%',
                backgroundColor: theme.colors.primary[1],
                zIndex: 0,
                transform: 'rotate(1.2deg)',
                borderRadius: theme.radius.md,
              },
              '::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                right: 16,
                height: '20%',
                width: '50%',
                backgroundColor: theme.colors.primary[1],
                zIndex: 0,
                transform: 'rotate(1.2deg)',
                borderRadius: theme.radius.md,
              },
            })}
          >
            <Tabs
              h="fit-content"
              value={noticeTab}
              onTabChange={(tab: NoticeTabValues) => setNoticeTab(tab)}
              header={<Header />}
              rightContent={<NoticePageTabsRightContent />}
              defaultTab="informations"
              tabListProps={{ sx: { borderBottom: 'none', zIndex: 10 } }}
              sx={theme => ({
                borderRadius: theme.radius.md,
                border: `1px solid ${theme.colors.gray[1]}`,
                zIndex: 2,
                position: 'relative',
                boxShadow: theme.shadows.md,
              })}
            >
              <Tabs.Button
                value="informations"
                icon={<IconAlignJustified size={14} stroke={2} />}
                label={t('tabs.information')}
              />
              <Tabs.Button
                value="summary-sheet"
                icon={<IconChecklist size={16} stroke={2} />}
                label={t('tabs.summarySheet')}
              />
              <Tabs.Button
                value="documents"
                icon={<IconFolder size={14} stroke={2} />}
                label={t('tabs.documents')}
              />
            </Tabs>
          </Box>
        </Stack>
        <Suspense fallback={<TabLoader />}>
          <Box px="07" m={0} w="100%" h="100%">
            {content}
          </Box>
        </Suspense>
      </Stack>
      <SidePanel
        oppened={sidePanelOpen}
        setActiveTab={setActiveSidebarTab}
        activeTab={activeSidePanelTab}
      />
    </NoticeProvider>
  );
}

function NoticePageTabsRightContent() {
  const { tender } = useNoticeContext();
  const { interaction } = tender;
  if (interaction?.decisionStatus === 'GO') {
    return (
      <BuyerProfileUpdateSubscriptionStatus
        isSubscribedToTenderUpdateOnBuyerProfile={
          interaction.isSubscribedToTenderUpdateOnBuyerProfile
        }
        buyerProfilUrl={tender.buyerProfileParticipationURL}
      />
    );
  }
  return null;
}

function BuyerProfileUpdateSubscriptionStatus({
  isSubscribedToTenderUpdateOnBuyerProfile,
  buyerProfilUrl,
}: {
  isSubscribedToTenderUpdateOnBuyerProfile: boolean;
  buyerProfilUrl?: string;
}) {
  const { t } = useTranslation(['common', 'domain']);
  const { tender } = useNoticeContext();
  const updateSubscriptionStatusMutation =
    useUpdateSubscriptionStatusMutation();
  return (
    <Group noWrap spacing="02">
      <Switch
        checked={isSubscribedToTenderUpdateOnBuyerProfile}
        color="primary"
        size="xs"
        onChange={e => {
          updateSubscriptionStatusMutation.mutate({
            tenderId: tender.id,
            isSubscribedToTenderUpdateOnBuyerProfile: e.target.checked,
          });
        }}
      />
      <Text fw={500} variant="sm" c="gray.9">
        {`${t('common:subscribtion')} `}
        {buyerProfilUrl ? (
          <Link to={buyerProfilUrl} target="_blank">
            {t('domain:buyerProfile')}
          </Link>
        ) : (
          <span>{t('domain:buyerProfile')}</span>
        )}
      </Text>
    </Group>
  );
}
