import { Accordion, Group, Stack, Text, Title } from '@mantine/core';
import { DatePickerInput, DatesProvider } from '@mantine/dates';
import type { UseFormReturnType } from '@mantine/form';

import {
  IconBarrierBlock,
  IconBox,
  IconChevronRight,
  IconHotelService,
} from '@tabler/icons-react';

import TenderStatusBadge from '../../../../../shared/components/UI/TenderStatusBadge/TenderStatusBadge';

import type { StreamFormValues } from '../../../../../shared/entities/StreamFilterSettings';
import { TenderStatus } from '../../../../../shared/entities/Tender';
import { BuyerMultiselect } from './BuyerMultiselect';
import CPVMultiselect from './CPVMultiselect';
import CheckboxCard from './CheckboxCard';
import CheckboxCardGroup from './CheckboxCardGroup';
import DepartmentMultiSelect from './DepartmentMultiSelect';
import { RenewalSwitch } from './RenewalSwitch';
import TooltipWithIcon from './TooltipWithIcon';
import { useTranslation } from 'react-i18next';
import { TendersWithoutCPVsSwitchContainer } from './TendersWithoutCPVsSwitchContainer';
import { TendersWithoutLocationSwitchContainer } from './TendersWithoutLocationSwitchContainer';

type AdvancedFieldsProps = {
  form: UseFormReturnType<
    StreamFormValues,
    (values: StreamFormValues) => StreamFormValues
  >;
  isOpened?: boolean;
};

export const AdvancedFields = ({
  form,
  isOpened = false,
}: AdvancedFieldsProps) => {
  const { t } = useTranslation('stream');

  return (
    <Accordion
      chevron={<IconChevronRight stroke="2px" />}
      defaultValue={isOpened ? 'advanced-filters' : undefined}
      styles={{
        chevron: {
          '&[data-rotate]': {
            transform: 'rotate(90deg)',
          },
        },
        content: {
          paddingLeft: '0px',
        },
      }}
    >
      <Accordion.Item value="advanced-filters" sx={{ borderBottom: 'none' }}>
        <Accordion.Control
          pl={0}
          mr={0}
          w="322px"
          sx={{
            ':hover': {
              background: 'white',
            },
            '.mantine-Accordion-chevro': {
              fontSize: '12px',
            },
          }}
        >
          <Title order={4}>{t('advancedSearch.title')}</Title>
        </Accordion.Control>
        <Accordion.Panel pl={0}>
          <Stack spacing="06">
            {/* status */}
            <Stack spacing="04">
              <Text variant="md" fw="600" c="gray.9">
                {t('status.statusSectionLabel')}
              </Text>
              <CheckboxCardGroup {...form.getInputProps('status')}>
                <CheckboxCard
                  value="OPEN"
                  icon={<TenderStatusBadge status={TenderStatus.OPEN} />}
                  description={t('status.openDescription')}
                  disabled={form.getTransformedValues().isRenewal}
                />
                <CheckboxCard
                  value="CLOSED"
                  icon={<TenderStatusBadge status={TenderStatus.CLOSED} />}
                  description={t('status.closedDescription')}
                  bottomAction={
                    <RenewalSwitch
                      status={form.getTransformedValues().status}
                      setStatus={value => form.setFieldValue('status', value)}
                      {...form.getInputProps('isRenewal')}
                    />
                  }
                />
              </CheckboxCardGroup>
            </Stack>
            {/* Category */}
            <Stack spacing="04">
              <Group w="100%">
                <Title order={5}>{t('category.categorySectionLabel')}</Title>
                <TooltipWithIcon content={t('category.categoryTooltip')} />
              </Group>
              <CheckboxCardGroup {...form.getInputProps('categories')}>
                <CheckboxCard
                  value="WORKS"
                  label={t('category.works')}
                  icon={<IconBarrierBlock size={16} stroke={1} />}
                  description={t('category.worksDescription')}
                />
                <CheckboxCard
                  value="SUPPLIES"
                  label={t('category.supplies')}
                  icon={<IconBox size={16} stroke={1} />}
                  description={t('category.suppliesDescription')}
                />
                <CheckboxCard
                  value="SERVICES"
                  label={t('category.services')}
                  icon={<IconHotelService size={16} stroke={1} />}
                  description={t('category.servicesDescription')}
                />
              </CheckboxCardGroup>
            </Stack>
            {/* Location */}
            <Stack spacing="04">
              <Group w="100%">
                <Title order={5}>{t('location.locationSectionLabel')}</Title>
                <TooltipWithIcon content={t('location.locationToolTip')} />
              </Group>
              <Stack w="100%" spacing="02">
                <DepartmentMultiSelect
                  {...form.getInputProps('locations')}
                  label={t('location.locationToInclude')}
                />
                <TendersWithoutLocationSwitchContainer
                  {...form.getInputProps('includeTendersWithoutDepartment')}
                />
              </Stack>
            </Stack>
            {/* CPV */}
            <Stack spacing="04">
              <Group w="100%">
                <Text variant="md" fw="600" c="gray.9">
                  {t('cpv.cpvCodeSectionLabel')}
                </Text>
                <TooltipWithIcon
                  content={t('cpv.cpvCodeTooltip')}
                />
              </Group>
              <Stack w="100%" spacing="02">
                <CPVMultiselect
                  {...form.getInputProps('includedCPVs')}
                  label={t('cpv.cpvToInclude')}
                />
                <TendersWithoutCPVsSwitchContainer
                  {...form.getInputProps('includeTendersWithoutCPV')}
                />
              </Stack>
              <CPVMultiselect
                {...form.getInputProps('excludedCPVs')}
                label={t('cpv.cpvToExclude')}
              />
            </Stack>
            {/* company name */}
            <Stack spacing="04">
              <Text variant="md" fw="600" c="gray.9">
                {t('buyer.buyerSectionLabel')}
              </Text >
              <BuyerMultiselect form={form} />
            </Stack >

            {/* date range */}
            < Stack spacing="04" >
              <Text variant="md" fw="600" c="gray.9">
                {t('dates.datesSectionLabel')}
              </Text>
              <DatesProvider settings={{ locale: 'fr' }}>
                <Group spacing="05">
                  <DatePickerInput
                    clearable
                    radius="md"
                    valueFormat="DD MMMM YYYY"
                    label={t('dates.afterPublicationDate')}
                    placeholder={t('dates.datePlaceholder')}
                    w="calc(50% - 12px)"
                    p="10px, 16px, 10px, 16px"
                    {...form.getInputProps('afterPublicationDate')}
                  />
                  <DatePickerInput
                    clearable
                    radius="md"
                    valueFormat="DD MMMM YYYY"
                    label={t('dates.beforePublicationDate')}
                    placeholder={t('dates.datePlaceholder')}
                    w="calc(50% - 12px)"
                    p="10px, 16px, 10px, 16px"
                    {...form.getInputProps('beforePublicationDate')}
                  />
                </Group>
                <Group spacing="05">
                  <DatePickerInput
                    clearable
                    radius="md"
                    valueFormat="DD MMMM YYYY"
                    label={t('dates.afterResponseDeadline')}
                    placeholder={t('dates.datePlaceholder')}
                    w="calc(50% - 12px)"
                    p="10px, 16px, 10px, 16px"
                    {...form.getInputProps('afterResponseDeadlineDate')}
                  />
                  <DatePickerInput
                    clearable
                    radius="md"
                    valueFormat="DD MMMM YYYY"
                    label={t('dates.beforeResponseDeadline')}
                    placeholder={t('dates.datePlaceholder')}
                    w="calc(50% - 12px)"
                    p="10px, 16px, 10px, 16px"
                    {...form.getInputProps('beforeResponseDeadlineDate')}
                  />
                </Group>
              </DatesProvider>
            </Stack >
          </Stack >
        </Accordion.Panel >
      </Accordion.Item >
    </Accordion >
  );
};
