import { Divider, Group, Skeleton, Stack, Text } from '@mantine/core';

import { IconFilter } from '@tabler/icons-react';

export function FullPage() {
  return (
    <>
      <FilterBar />
      <Table />
    </>
  );
}

export function Table() {
  return (
    <Stack px="05" w="100%" spacing={0}>
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
    </Stack>
  );
}

export function FilterBar() {
  return (
    <Group spacing="02" mb="08" px="05" mt="02">
      <Skeleton height={20} width={110} radius="sm" />
      <Divider orientation="vertical" h="04" my="auto" />
      <Group spacing="01" c="gray.6">
        <IconFilter size={20} />
        <Text variant="sm" fw="400" c="gray.6">
          Filtrer
        </Text>
      </Group>
      <Skeleton height={20} width={110} radius="sm" />
      <Skeleton height={20} width={136} radius="sm" />
      <Skeleton height={20} width={152} radius="sm" />
    </Group>
  );
}

function ItemSkeleton() {
  return (
    <Group
      noWrap
      sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[2]}` })}
    >
      <Skeleton circle width={32} height={32} />
      <Stack w="100%" h="09" spacing="02" justify="center" m={0}>
        <Skeleton height={10} width={200} radius="sm" />
        <Skeleton height={10} width={100} radius="sm" />
      </Stack>
    </Group>
  );
}
