import { Accordion, Box, Group, Text } from '@mantine/core';

import type { Document } from '../../../../../shared/entities/Document';
import { sortDocumentsPerFileExtension } from '../utils/sortingDocumentsPerFileExtension';
import { sortDocumentsPerType } from '../utils/sortingDocumentsPerType';
import { DocumentComponent } from './DocumentComponent';

type DocumentsAccordionProps = {
  documents: Document[];
  openDocumentSidePanel: (document: Document) => void;
  title: string;
  sortBy?: 'type' | 'fileExtension';
};

export function DocumentsAccordion({
  documents,
  title,
  sortBy = 'type',
  openDocumentSidePanel,
}: DocumentsAccordionProps) {
  const sortedDocuments =
    sortBy === 'type'
      ? sortDocumentsPerType(documents)
      : sortDocumentsPerFileExtension(documents);

  return (
    <Accordion
      unstyled
      chevronPosition="left"
      defaultValue={title}
      styles={theme => ({
        chevron: {
          color: theme.colors.gray[6],
          display: 'flex',
          alignItems: 'center',
          marginRight: theme.spacing['01'],
          transform: 'rotate(180deg)',
          '&[data-rotate]': {
            transform: 'rotate(360deg)',
          },
          transition: 'transform 300ms ease',
        },
        label: {
          paddingTop: theme.spacing['00'],
          paddingBottom: theme.spacing['00'],
        },
        control: {
          borderRadius: theme.radius.md,
          display: 'flex',
          alignItems: 'center',
          border: 'none',
          backgroundColor: 'transparent',
          ':hover': {
            backgroundColor: theme.colors.gray[0],
            cursor: 'pointer',
          },
        },
      })}
    >
      <Accordion.Item value={title}>
        <Accordion.Control mb="03" py={0} sx={{}}>
          <Group noWrap spacing="02" px="02">
            <Text variant="sm" fw="500" color="gray.9">
              {title}
            </Text>
            <Box
              w="01"
              h="01"
              bg="gray.2"
              sx={theme => ({ borderRadius: theme.radius.lg })}
            />
            <Text variant="sm" fw="400" c="gray.6">
              {documents.length} documents
            </Text>
          </Group>
        </Accordion.Control>
        <Accordion.Panel pl="03">
          {sortedDocuments.map(document => (
            <DocumentComponent
              key={document.id}
              document={document}
              openDocumentSidePanel={openDocumentSidePanel}
            />
          ))}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}
