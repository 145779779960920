import { notifications } from '@mantine/notifications';

import {
  IconCheck,
  IconExclamationMark,
  IconFileReport,
  IconX,
} from '@tabler/icons-react';
import { t } from 'i18next';

import type { DecisionRenewalStatus } from '../../entities/Interaction';
import { DecisionStatus } from '../../entities/Interaction';
import { RagBlockingReason, type RagGenerability } from '../../entities/Tender';

export const displayDecisionNotification = (
  status: DecisionStatus | DecisionRenewalStatus,
  ragGenerability?: RagGenerability,
) => {
  switch (status) {
    case DecisionStatus.TO_ANALYZE:
      displayToAnalyzeNotification(ragGenerability);
      break;
    case DecisionStatus.REJECTED:
      notifications.show({
        title: t('notification:decisions.rejected.title'),
        message: t('notification:decisions.rejected.message'),
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'red.6',
        icon: <IconX size="18" />,
      });
      break;
    case DecisionStatus.NOGO:
      notifications.show({
        title: t('notification:decisions.nogo.title'),
        message: t('notification:decisions.nogo.message'),
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'red.6',
        icon: <IconX size="18" />,
      });
      break;
    case DecisionStatus.GO:
      notifications.show({
        title: t('notification:decisions.go.title'),
        message: t('notification:decisions.go.message'),
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'primary.6',
        icon: <IconCheck size="18" />,
      });
      break;
    case DecisionStatus.ANSWERED:
      notifications.show({
        title: t('notification:decisions.answered.title'),
        message: t('notification:decisions.answered.message'),
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'primary.6',
        icon: <IconCheck size="18" />,
      });
      break;
    case DecisionStatus.WIN:
      notifications.show({
        title: t('notification:decisions.win.title'),
        message: t('notification:decisions.win.message'),
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'primary.6',
        icon: <IconCheck size="18" />,
      });
      break;
    case DecisionStatus.LOSS:
      notifications.show({
        title: t('notification:decisions.lost.title'),
        message: t('notification:decisions.lost.message'),
        radius: 'md',
        autoClose: 2000,
        withBorder: true,
        color: 'red.6',
        icon: <IconCheck size="18" />,
      });
      break;
  }
};

function displayToAnalyzeNotification(ragGenerability?: RagGenerability) {
  if (!ragGenerability) {
    throw new Error('RAG generability data is missing');
  }

  if (ragGenerability.isGeneratable) {
    notifications.show({
      title: t('notification:decisions.toAnalyze.isGeneratable.title'),
      message: t('notification:decisions.toAnalyze.isGeneratable.message'),
      radius: 'md',
      autoClose: 2000,
      withBorder: true,
      color: 'primary.6',
      icon: <IconCheck size="18" />,
    });
  } else if (ragGenerability.error === RagBlockingReason.NO_DOCUMENTS) {
    notifications.show({
      title: t('notification:decisions.toAnalyze.missingDocuments.title'),
      message: t('notification:decisions.toAnalyze.missingDocuments.message'),
      radius: 'md',
      autoClose: 2000,
      withBorder: true,
      color: 'primary.6',
      icon: <IconFileReport size="18" />,
    });
  } else if (
    ragGenerability.error === RagBlockingReason.NO_SUPPORTED_DOCUMENTS
  ) {
    notifications.show({
      title: t('notification:decisions.toAnalyze.unsupportedDocuments.title'),
      message: t(
        'notification:decisions.toAnalyze.unsupportedDocuments.message',
      ),
      radius: 'md',
      autoClose: 2000,
      withBorder: true,
      color: 'primary.6',
      icon: <IconExclamationMark size="18" />,
    });
  }
}
