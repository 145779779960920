import type { ComponentPropsWithoutRef } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import { Box, MultiSelect, Stack, Text } from '@mantine/core';

import { getDepartments } from '../../../../../shared/api/magellan/departments';
import type { FrenchDepartment } from '../../../../../shared/entities/FrenchDepartment';
import { useTranslation } from 'react-i18next';

type DepartmentMultiSelectProps = {
  value: string[];
  onChange: (values: string[]) => void;
  label: string;
};

export default function DepartmentMultiSelect({
  value,
  onChange,
  label,
}: DepartmentMultiSelectProps) {
  const [departments, setDepartments] = useState<BuyerItemProps[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const { t } = useTranslation('stream');

  const handleValueChanges = (values: string[]) => {
    setValues(values);
    onChange(values)
  };

  useEffect(() => {
    const setInitialValues = async (initialLocations: string[]) => {
      setValues(initialLocations);
    };
    if (value?.length) {
      setInitialValues(value);
    }
  }, [value]);

  useEffect(() => {
    const fetchDepartments = async () => {
      const rawDepartments: FrenchDepartment[] = await getDepartments();
      const formatedDepartments = rawDepartments.map(department => ({
        ...department,
        label: `${department.code} - ${department.name}`,
        value: department.code,
      }));
      setDepartments(formatedDepartments);
    };

    fetchDepartments();
  }, []);

  return (
    <Stack spacing="00">
      <Text variant="sm" fw="500" c="gray.9">
        {label}
      </Text>
      <MultiSelect
        placeholder={t('location.departementMultiSelectPlaceholder')}
        radius="md"
        searchable
        value={values}
        onChange={handleValueChanges}
        searchValue={searchValue}
        onSearchChange={setSearchValue}
        nothingFound={t('location.departementMultiSelectNothingFound')}
        data={departments}
        itemComponent={SelectDepartmentItem}
        maxDropdownHeight={160}
        filter={(value, selected, item) => {
          return (
            !selected &&
            (item.code.toLowerCase().includes(value.toLowerCase().trim()) ||
              item.name.toLowerCase().includes(value.toLowerCase().trim()))
          );
        }}
      />
    </Stack>
  );
}

interface BuyerItemProps extends ComponentPropsWithoutRef<'div'> {
  code: string;
  name: string;
  value: string;
  label: string;
}

const SelectDepartmentItem = forwardRef<HTMLDivElement, BuyerItemProps>(
  (props: BuyerItemProps, ref) => {
    const { name, code, ...others } = props;
    return (
      <Box ref={ref} {...others}>
        <Text truncate>{`${code} - ${name}`}</Text>
      </Box>
    );
  },
);
