import { Text } from '@mantine/core';
import type { MRT_Cell } from 'mantine-react-table';

import type { TenderWithListData } from '../../../../../../shared/api/magellan/interaction/dto';
import { formatCurrency } from '../../../../../../shared/utils/curencies';

type AmountCellData = {
  amount: number;
};

type AmountCellProps = {
  cell: MRT_Cell<TenderWithListData>;
};

export function WinAmountCell({ cell }: AmountCellProps) {
  const { amount } = cell.getValue<AmountCellData>();

  return (
    <Text variant="sm" fw={400} c="gray.9" truncate>
      {amount == null ? '-' : formatCurrency(amount)}
    </Text>
  );
}
