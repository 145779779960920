import { Box, Flex, Select } from '@mantine/core';

import { IconChevronDown } from '@tabler/icons-react';

import { useSidepanelContext } from '../UI/Sidepanel/SidepanelInternalData.provider';

import type { Document } from '../../entities/Document';
import { DocumentPreview } from '../DocumentPreview/DocumentPreview';
import { DownloadFileButton } from '../DownloadFileButton';

const DOCUMENT_SIDE_PANEL_WIDTH = 800;

export type DocumentSidepanelContext = {
  documents: Document[];
  selectedDocument: Document;
};

export function Header() {
  const {
    data: { documents, selectedDocument },
    updateData,
  } = useSidepanelContext<DocumentSidepanelContext>();
  const selectData = documents.map(document => ({
    value: document.id.toString(),
    label: document.prettyName,
  }));

  function handleSourceChange(value: string) {
    const newSelectedDocument = documents.find(
      document => document.id.toString() === value,
    );

    if (!newSelectedDocument) {
      throw new Error('Selected document not found');
    }

    updateData({
      documents,
      selectedDocument: newSelectedDocument,
    });
  }

  return (
    <Flex justify="space-between" w="100%">
      <Flex justify="center" align="center" w="100%">
        <Select
          styles={theme => ({
            root: { minWidth: '450px' },
            input: {
              border: 'none',
              textAlign: 'center',
              fontWeight: 500,
              color: theme.colors.gray[9],
              fontSize: theme.fontSizes.sm,
            },
            dropdown: { minWidth: 'fit-content' },
            item: { wordSpacing: 'nowrap' },
          })}
          rightSection={<IconChevronDown size={16} />}
          value={selectedDocument.id.toString()}
          data={selectData}
          onChange={handleSourceChange}
        />
      </Flex>

      <DownloadFileButton
        fileURL={selectedDocument.fileURL}
        prettyName={selectedDocument.prettyName}
      />
    </Flex>
  );
}

export function Content() {
  const {
    data: { selectedDocument },
  } = useSidepanelContext<DocumentSidepanelContext>();

  return (
    <Box px="04" w={DOCUMENT_SIDE_PANEL_WIDTH} h="100%">
      <DocumentPreview
        initialPage={1}
        fileURL={selectedDocument.fileURL}
        prettyName={selectedDocument.prettyName}
      />
    </Box>
  );
}
