import { Group } from '@mantine/core';

import { IconPencil } from '@tabler/icons-react';
import { useIsMutating } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../shared/components/UI/Button/Button';

import { updateSummaryCriterionAnswer } from '../../../../../shared/api/magellan/summarySheet';
import { useSidepanel } from '../../../../../shared/contexts/Sidepanel.provider';
import { useNoticeContext } from '../../../contexts/Notice.provider';
import { useSaveCriteriaConfirmationModal } from '../hooks/useSaveCriteriaConfirmationModal';
import { LoaderNotif, LoadingState } from './LoaderNotif';
import { CriteriaSidepanelContent } from './summarySheetPanel/CriteriaSidepanelContent';

export const Footer = () => {
  const { t } = useTranslation(['summarySheet', 'common']);
  const { tender } = useNoticeContext();

  const { askUserToSaveBeforeClosingPanel } =
    useSaveCriteriaConfirmationModal();

  const { togglePanel, closePanel } = useSidepanel({
    onBeforeClose: askUserToSaveBeforeClosingPanel,
  });

  const numberOfUpdates = useIsMutating({
    mutationKey: [updateSummaryCriterionAnswer.name, 'mainPage'],
  });
  const isLoading = numberOfUpdates > 0;

  const openSidepanel = () => {
    togglePanel({
      header: 'Fiche de synthèse',
      content: (
        <CriteriaSidepanelContent
          tenderId={tender.id}
          closePanel={closePanel}
          ragGenerability={tender.ragGenerability}
        />
      ),
      initialData: {
        criteria: [],
        hasChanges: false,
        hasErrors: false,
        validateCriteria: () => false,
      },
    });
  };

  return (
    <Group
      p="03"
      px="07"
      spacing="02"
      position="right"
      w="100vw"
      bg="white"
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);`,
      }}
    >
      <LoaderNotif
        loadingState={isLoading ? LoadingState.LOADING : LoadingState.WAITING}
      />
      <Button
        variant="default"
        size="sm"
        leftIcon={<IconPencil />}
        onClick={openSidepanel}
      >
        {t('footer.edit')}
      </Button>
    </Group>
  );
};
